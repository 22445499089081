@media (max-width: 768px) {
  .wekContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .wekContainer--overlay {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.125);
  }

  #wektest {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
padding: 24px 12px;
    overflow-y: scroll;
  }

  #wektestmobile {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
padding: 24px;
z-index: 100;
background-color: rgba(0, 74, 35, 0.25);
position: fixed;
top: 0%;
  }
}



@media (min-width: 769px) {
  .wekContainer {
    min-height: 75vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: none;
  }

  .wekContainer--overlay {
    min-height: 75vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;
    background-color: rgba(0, 74, 35, 1);
    position: relative;
  } 

  .calloutContainer {
   display: none;
  }

  .calloutContainer--overlay {
    min-height: 100vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 74, 35, 0.5);
    z-index: 28;
    position: fixed;
    left: 0%; 
    top: 0%;
  } 

  #wektest {
    min-height: 75vh;
    height: auto;
    max-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 24px;
    box-shadow:
      inset -8px 0 8px -8px rgba(0, 0, 0, 0.125),
      inset 8px 0 8px -8px rgba(0, 0, 0, 0.125);
  }

  #wektestday {
    min-height: auto;
    height: auto;
    max-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: 0px 24px;
    padding: 6px ;
    overflow-x: none;
    position: fixed;
    z-index: 1000;
    background-color: #004a22;
    box-shadow: 10px 10px 10px rgba(0,74,34,0.5);
    border-radius: 8px;
  }

  #wektestday:hover {
    cursor: grab; 
  }

  
  #wektestday:active {
    cursor: grabbing; 
  }

  .board--scale {
      background-color: #e2dfcf;
      /* min-height: 100vh; */
      /* like display:flex but will allow bleeding over the window width */
      width: 100%;
      display: inline-flex;
      overflow-x: scroll;
  }

  .board--scale--day {
      /* like display:flex but will allow bleeding over the window width */
      display: inline-flex;
      overflow-y: hidden;
      background-color: #e2dfcf;
      border-radius: 8px;
      z-index: 10000;
  }

  #wektest::-webkit-scrollbar {
    width: 0px;
    background-color: rgba(0, 74, 35, 0.05);
    border-radius: 5px;
  }

  #wektest::-webkit-scrollbar-thumb {
    background-color: rgba(0, 74, 35, 0.3);
    width: 0px;
    height: 0px;
    border-radius: 5px;
  }

}
