@media (max-width: 480px) {
  .quizIntroMain {
    height: auto;
    max-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 12px;
  }

  /* #titleDivMain {
    height: auto;
    width: 90%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    background-color: #447364;
    color: #004a22;
  } */

  #titleDivMain {
    height: auto;
    width: 90%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    background-color: #447364;
    color: #004a22;
  }

  .quizPage {
    padding: 24px 6px;
  }

  .title__image {
    z-index: 10;
    border-radius: 5px;
  }

  .clickAnywhere {
    position: relative;
    z-index: 11;
    bottom: 24px;
    animation: clickAnimation 2s linear infinite reverse backwards;
    background-color: #447364;
  }

  @keyframes clickAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .quizDiv {
    color: #004a22;
    height: 70vh;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
  }

  .newMenu {
    height: 75vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 388px;
    z-index: 9;
    color: #004a22;
    overflow: scroll;
  }

  #detailsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  #detailsDiv a {
    padding: 2px 12px 2px 2px;
  }

  .columnMenu {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 6px 12px;
    margin-bottom: 12px;
  }

  .columnMenu div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  /* .columnMenu span,
  .columnMenu label {
    padding: 0px 12px 0px 6px;
  } */

  .columnMenu img {
    display: none;
  }

  .bar-container {
    width: 200px; /* Adjust the width as needed */
    height: 20px;
    background-color: #e2dfcf;
    overflow: hidden;
    border: 2px solid #e2dfcf;
    border-radius: 5px;
    margin-top: 24px;
  }

  #detailsDiv label {
    margin-bottom: 12px;
  }

  .filling-bar {
    height: 100%;
    width: 0;
    background-color: rgba(0, 74, 35); /* Adjust the color as needed */
    transition: width 2s ease; /* Smooth transition effect */
    z-index: 1000;
  }

  .mobileImage img {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 12px;
  }

  .newMenu .columnMenu {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 12px;
    margin-top: 12px;
  }

  .columnMenu:not(:last-child) {
    border-bottom: 1px solid rgba(0, 74, 35, 0.2);
  }

  .leftCol {
    min-height: 60vh;
    height: auto;
    width: 100%;
    border-right: 3px solid rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid whitesmoke;
    border-radius: 5px 5px 5px 0;
    overflow-y: scroll;
    padding: 12px;
    background-color: whitesmoke;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .leftCol p {
    font-size: medium;
  }

  .quizHeaderWhite {
    color: #004a22;
    font-size: x-large;
  }

  .rightCol {
    display: none;
  }

  .buttonRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background-color: whitesmoke;
    border-radius: 0 0 5px 5px;
    border-top: 3px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  .buttonRow button {
    font-weight: 600;
  }

  .quizButton a {
    text-decoration: none;
  }

  .quizButton,
  .quizButton:hover,
  .quizButton:checked,
  .quizButton:active,
  .quizButton:focus,
  .quizButton:focus-visible,
  .quizButton:focus-within,
  .quizButton:visited {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    box-shadow: none;
    color: #004a22;
    border: none;
    box-shadow: none;
    margin-left: 6px;
  }

  .quizButton:first-child {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
  }

  .titleDiv {
    height: auto;
    padding: 16px 20px;
    width: 90%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    background-color: #f5f5f5;
    color: #004a22;
    margin-bottom: 24px;
  }

  .titleDiv.main {
    height: auto;
    width: 90%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    background-color: #f5f5f5;
    color: #004a22;
  }

  .emphasis {
    font-size: 1.3em;
    background-color: whitesmoke;
    padding: 2px 4px 2px 4px;
    color: #004a22;
    border-radius: 5px;
  }

  .videoPlayerQuiz {
    margin-top: 12px;
    height: auto;
    width: 100%;
    border-radius: 20px;
    border: 10px solid #004a22;
  }

  .modernOnlineExperienceButton {
    margin: 0 6px;
    color: #e2dfcf;
  }

  .modernOnlineExperienceButton:hover {
    color: #004a22;
    cursor: pointer;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .quizIntroMain {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .quizDiv {
    color: #004a22;
    height: 70vh;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
  }

  #titleDivMain {
    height: auto;
    width: 90%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    background-color: #447364;
    color: #004a22;
    margin: 24px 0 48px 0;
  }

  .clickAnywhere {
    z-index: 11;
    position: relative;
    bottom: 48px;
    animation: clickAnimation 2s linear infinite reverse backwards;
  }

  @keyframes clickAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .newMenu {
    height: 75vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 388px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 9;
    color: #004a22;
    overflow: scroll;
  }

  #detailsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  #detailsDiv a {
    padding: 2px 12px 2px 2px;
  }

  .columnMenu {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 0 12px;
  }

  .columnMenu div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .columnMenu span,
  .columnMenu label {
    padding: 0px 12px 0px 6px;
  }

  .columnMenu img {
    display: none;
  }

  .bar-container {
    width: 200px; /* Adjust the width as needed */
    height: 20px;
    background-color: #e2dfcf;
    overflow: hidden;
    border: 2px solid #e2dfcf;
    border-radius: 5px;
    margin-top: 24px;
  }

  .filling-bar {
    height: 100%;
    width: 0;
    background-color: rgba(0, 74, 35); /* Adjust the color as needed */
    transition: width 2s ease; /* Smooth transition effect */
    z-index: 1000;
  }

  .mobileImage img {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 12px;
  }

  .newMenu .columnMenu {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
  }

  .columnMenu:not(:last-child) {
    border-bottom: 1px solid rgba(0, 74, 35, 0.2);
  }

  .leftCol {
    height: 60vh;
    width: 100%;
    border-right: 3px solid rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid whitesmoke;
    border-radius: 5px 5px 0px 0;
    overflow-y: scroll;
    padding: 12px;
    background-color: whitesmoke;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .leftCol p {
    font-size: medium;
  }

  .rightCol {
    display: none;
  }

  .buttonRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background-color: whitesmoke;
    border-radius: 0 0 5px 5px;
    border-top: 3px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .quizButton a {
    text-decoration: none;
  }

  .quizButton,
  .quizButton:hover,
  .quizButton:checked,
  .quizButton:active,
  .quizButton:focus,
  .quizButton:focus-visible,
  .quizButton:focus-within,
  .quizButton:visited {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    box-shadow: none;
    color: #004a22;
    border: none;
    box-shadow: none;
    margin-left: 6px;
  }

  .quizButton:first-child {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
  }

  .titleDiv {
    height: auto;
    width: 90%;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    margin: 1em;
    z-index: 10;
    color: #004a22;
  }

  .emphasis {
    font-size: 1.3em;
    background-color: whitesmoke;
    padding: 2px 4px 2px 4px;
    color: #004a22;
    border-radius: 5px;
  }

  .videoPlayerQuiz {
    margin-top: 12px;
    height: auto;
    width: 100%;
    border-radius: 20px;
    border: 10px solid #004a22;
  }

  .modernOnlineExperienceButton {
    margin: 0 6px;
    color: #e2dfcf;
  }

  .modernOnlineExperienceButton:hover {
    color: #004a22;
    cursor: pointer;
  }

  .title__image {
    z-index: 10;
    object-fit: fill;
  }
}

@media (min-width: 1025px) {
  .quizIntroMain {
    height: 60%;
    width: 60%;
    color: #004a22;
    display: flex;
    padding: 24px 48px 48px 48px;
    border-radius: 8px;
  }

  .mobileImage {
    display: none;
  }

  .silvImg_Desktop {
    border-radius: 0 5px 0 0;
    width: 100%;
    height: 100%; /* Set the height to 100% of its container */
    object-fit: cover; /* This property ensures the image covers the container while maintaining its aspect ratio */
  }

  .quizDiv {
    color: #004a22;
    height: 100%;
    width: 95%;
    display: grid;
    grid-template-rows: repeat(11, 7.5vh);
    grid-template-columns: repeat(50, 1fr);
    padding: 24px 48px 48px 48px;
  }

  .title__image {
    z-index: 10;
    border-radius: 8px;
    transform: scale(1.1);
  }

  .newMenu {
    min-height: 750px;
    height: auto;
    width: 100%;
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px 5px 0 0;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    min-height: 388px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 9;
    color: #004a22;
  }

  .columnMenu {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 12px 0;
  }

  .columnMenu div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #detailsDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 12px 0;
  }

  #detailsDiv a {
    padding-right: 12px;
  }

  .columnMenu label {
    font-size: 0.75em;
  }

  .columnMenu span {
    padding-right: 12px;
  }

  .columnMenu img {
    border-radius: 10px;
  }

  .columnMenu.one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    height: 100%;
    width: 100%;
  }

  .columnMenu.two {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    height: 100%;
    width: 100%;
  }

  .columnMenu.three {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    height: 100%;
    width: 100%;
  }

  .columnMenu.four {
    grid-row: 2 / 2;
    grid-column: 2 / 2;
    height: 100%;
    width: 100%;
  }

  .bar-container {
    min-width: 200px; /* Adjust the width as needed */
    height: 20px;
    background-color: #e2dfcf;
    overflow: hidden;
    border: 2px solid #e2dfcf;
    border-radius: 5px;
  }

  .filling-bar {
    height: 100%;
    width: 0;
    background-color: rgba(0, 74, 35); /* Adjust the color as needed */
    transition: width 2s ease; /* Smooth transition effect */
    z-index: 1000;
  }

  .titleDiv {
    min-height: 60vh;
    max-height: 75vh;
    min-width: 70%;
    max-width: 90%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    border: 2px solid #004a22;
    z-index: 10;
    color: #004a22;
    padding: 80px 24px;
    margin: 144px 24px 48px 24px;
  }

  #titleDivMain {
    min-height: 100%;
    max-width: 100%;
    position: relative;
    /* transform: scale(1.1); */
    top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10;
    background-color: #447364;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 74, 34, 0.2);
    overflow: hidden;
  }

  @keyframes clickAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .clickAnywhere {
    position: relative;
    z-index: 11;
    bottom: 96px;
    animation: clickAnimation 2s linear infinite reverse backwards;
  }

  .titleDiv h1 {
    font-size: clamp(5vw, 8vw, 10vw);
    margin-bottom: 24px;
  }

  .titleDiv:hover {
    cursor: pointer;
  }

  .introExplainer {
    display: none;
  }

  .colLabel2 {
    display: none;
  }

  .quizHeaderDiv:hover .quizHeaderGreen,
  .quizHeaderDiv:hover .colLabel1 {
    display: none;
  }

  .quizHeaderDiv:hover .introExplainer,
  .quizHeaderDiv:hover .colLabel2 {
    display: block;
  }

  .hoverText {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    font-size: 0.75em;
  }

  .quizHeaderGreen {
    color: #004a22;
  }

  .quizHeaderWhite {
    color: #004a22;
    margin-top: 3px;
    font-size: x-large;
  }

  .quizHeaderDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .boldHeader {
    font-size: 1.6em;
  }

  .emphasis {
    font-size: 1.3em;
    background-color: whitesmoke;
    padding: 2px 10px 2px 4px;
    color: #004a22;
    border-radius: 5px;
    text-indent: -12px;
  }

  .emphasis:hover {
    box-shadow: 3px 3px 5px grey;
  }

  .leftCol {
    grid-row: 1 / 11;
    grid-column: 1 / 18;
    border-right: 3px solid rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid whitesmoke;
    border-radius: 5px 0 0 0;
    overflow-y: scroll;
    padding: 12px;
    background-color: whitesmoke;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .leftCol p, 
  .leftCol span, 
  .leftCol strong {
    font-size: medium;
  }

  .leftCol a {
    font-size: medium;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

  .colTitle {
    grid-row: 1 / auto;
    grid-column: 1 / 18;
    border-bottom: 3px solid #004a22;
  }

  .learn {
    margin: 0;
    padding: 0;
  }

  .title {
    color: #004a22;
  }

  .colContent {
    grid-row: 3 / 11;
    grid-column: 1 / 18;
    overflow: scroll;
  }

  .rightCol {
    grid-row: 1 / 11;
    grid-column: 18 / 51;
    background-color: whitesmoke;
    border-radius: 0 5px 0 0;
    /* box-shadow: 0px -10px 10px rgba(0,0,0,0.2); */
  }

  .leftCol.silvs {
    grid-row: 1 / 11;
    grid-column: 1 / 22;
    border-right: 3px solid rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid whitesmoke;
    border-radius: 5px 0 0 0;
    overflow-y: scroll;
    padding: 12px;
    background-color: whitesmoke;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .rightCol.silvs {
    grid-row: 1 / 11;
    grid-column: 22 / 51;
    background-color: whitesmoke;
    border-radius: 0 5px 0 0;
    display: flex;
    align-items: flex-start;
  }

  .rightCol:hover {
    cursor: pointer;
  }

  .rightContent {
    grid-row: 1 / 11;
    grid-column: 18 / 51;
    z-index: 5;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 12px 24px;
    color: #004a22;
    overflow: hidden;
  }

  .rightContentContent {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 24px;
  }

  .buttonRow {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-row: 11 / 12;
    grid-column: 1 / 51;
    padding: 4px;
    background-color: whitesmoke;
    border-radius: 0 0 5px 5px;
    border-top: 3px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }

  .quizButton a {
    text-decoration: none;
  }

  .quizButton,
  .quizButton:hover,
  .quizButton:checked,
  .quizButton:active,
  .quizButton:focus,
  .quizButton:focus-visible,
  .quizButton:focus-within,
  .quizButton:visited {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    box-shadow: none;
    color: #004a22;
    border: none;
    box-shadow: none;
    margin-left: 6px;
  }

  .introExplainer {
    text-align: flex-start;
  }

  .subtitle {
    margin-bottom: 8px;
  }

  .videoPlayerQuiz {
    height: 75vh;
    width: 100%;
    border-radius: 20px;
    border: 10px solid #004a22;
  }

  .imageOrGif {
    border-radius: 5px;
    border: 10px solid #004a22;
  }

  .background-image {
    height: auto;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .background-image-two {
    height: auto;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .background-image-three {
    height: auto;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .background-image-four {
    height: auto;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .background-image-five {
    height: auto;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .modernOnlineExperienceButton {
    color: #e2dfcf;
    margin: 0 6px;
  }

  .modernOnlineExperienceButton:hover {
    color: #004a22;
    cursor: pointer;
  }
}
