/* General Styling */
.privacypolicy--container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .tile--privacy {
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 2rem;
    color: #222;
    text-align: center;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  /* Lists */
  .list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .list.main {
    font-weight: bold;
    margin-top: 20px;
    font-size: 1.1rem;
  }
  
  .list.sub {
    margin-left: 20px;
    font-size: 1rem;
    font-weight: normal;
  }
  
  .listItem {
    margin: 8px 0;
    padding-left: 15px;
    position: relative;
  }
  
  .listItem::before {
    content: '\2022'; /* Bullet point */
    color: #004a22;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  
  .listHeader {
    font-size: 1.2rem;
    margin-top: 20px;
    color: #004a22;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacypolicy--container {
      padding: 15px;
    }
  
    .tile--privacy {
      padding: 20px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    p,
    .listItem,
    .listHeader {
      font-size: 1rem;
    }
  }
  