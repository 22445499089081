@media (max-width: 480px) {
  .profile__menu--screen {
    min-height: 35vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 12px 12px 12px;
  }

  .profile__menu {
    height: 50%;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .profile__menu a,
  .profile_menu a:hover {
    text-decoration: none;
  }

  .profile__menu .profile--button {
    height: 100%;
    width: 100%;
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    background-color: #004a22;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding: 8px;
  }

  .profile--button h2 {
    color: #e2dfcf;
  }

  .journal--log--table {
    width: 100%;
    height: auto;
    background-color: #004a22;
    border-radius: 8px;
  }

  .journal--log--table--row {
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }

  .journal--log--table--row:nth-child(1) {
    border-radius: 8px 8px 0 0;

  }

  .journal--log--table--row:last-child() {
    border-radius: 0 0 8px 8px;

  }
  
  .table--row--buttons {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }

  .journal--log--table--row td
   {
   width: 50%; 
   display: flex; 
   justify-content: center;

  }

  .journal--log--table--row td, 
  .journal--log--table--row td a
   {
    color: #e2dfcf; 
    text-decoration: none;
  }

  .journal--log--table--row td:not(:first-child):hover, 
  .journal--log--table--row td:hover .journal--log--table--row td a
   {
    background-color: #e2dfcf;
    color: #004a22;
    cursor: pointer;
    border-radius: 8px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .profile__menu--screen {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profile__menu {
    height: 50%;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .profile__menu a,
  .profile_menu a:hover {
    text-decoration: none;
  }

  .profile__menu .profile--button {
    height: 100%;
    width: 100%;
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    background-color: #004a22;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding: 6px;
  }

  .profile--button h2 {
    color: #e2dfcf;
  }

  .journal--log--table {
    width: 80%;
    height: auto;
    background-color: #004a22;
    border-radius: 8px;
    padding: 24px
  }

  .journal--log--table--row {
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }

  .journal--log--table--row:nth-child(1) {
    border-radius: 8px 8px 0 0;

  }

  .journal--log--table--row:last-child() {
    border-radius: 0 0 8px 8px;

  }
  
  .table--row--buttons {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }

  .journal--log--table--row td
   {
   width: 50%; 
   display: flex; 
   justify-content: center;

  }

  .journal--log--table--row td, 
  .journal--log--table--row td a
   {
    color: #e2dfcf; 
    text-decoration: none;
  }

  .journal--log--table--row td:not(:first-child):hover, 
  .journal--log--table--row td:hover .journal--log--table--row td a
   {
    background-color: #e2dfcf;
    color: #004a22;
    cursor: pointer;
    border-radius: 8px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .profile__menu--screen {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profile__menu {
    height: 50%;
    width: 75%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .profile__menu a,
  .profile_menu a:hover {
    text-decoration: none;
  }

  .profile__menu .profile--button {
    height: 100%;
    width: 100%;
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    background-color: #004a22;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
  }

  .profile--button h2 {
    color: #e2dfcf;
  }

  .journal--log--table {
    width: 80%;
    height: auto;
    background-color: #004a22;
    border-radius: 8px;
    padding: 24px
  }

  .journal--log--table--row {
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }

  .journal--log--table--row:nth-child(1) {
    border-radius: 8px 8px 0 0;

  }

  .journal--log--table--row:last-child() {
    border-radius: 0 0 8px 8px;

  }
  
  .table--row--buttons {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }

  .journal--log--table--row td
   {
   width: 50%; 
   display: flex; 
   justify-content: center;

  }

  .journal--log--table--row td, 
  .journal--log--table--row td a
   {
    color: #e2dfcf; 
    text-decoration: none;
  }

  .journal--log--table--row td:not(:first-child):hover, 
  .journal--log--table--row td:hover .journal--log--table--row td a
   {
    background-color: #e2dfcf;
    color: #004a22;
    cursor: pointer;
    border-radius: 8px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .profile__menu--screen {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profile__menu {
    height: 50%;
    width: 65%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .profile__menu a,
  .profile_menu a:hover {
    text-decoration: none;
  }

  .profile__menu .profile--button {
    height: 100%;
    width: 100%;
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    background-color: #004a22;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
  }

  .profile--button h2 {
    color: #e2dfcf;
  }

  .journal--log--table {
    width: 80%;
    height: auto;
    background-color: #004a22;
    border-radius: 8px;
    padding: 24px
  }

  .journal--log--table--row {
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }

  .journal--log--table--row:nth-child(1) {
    border-radius: 8px 8px 0 0;

  }

  .journal--log--table--row:last-child() {
    border-radius: 0 0 8px 8px;

  }
  
  .table--row--buttons {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }

  .journal--log--table--row td
   {
   width: 50%; 
   display: flex; 
   justify-content: center;

  }

  .journal--log--table--row td, 
  .journal--log--table--row td a
   {
    color: #e2dfcf; 
    text-decoration: none;
  }

  .journal--log--table--row td:not(:first-child):hover, 
  .journal--log--table--row td:hover .journal--log--table--row td a
   {
    background-color: #e2dfcf;
    color: #004a22;
    cursor: pointer;
    border-radius: 8px;
  }
}

@media (min-width: 1201px) {
  .profile__menu--screen {
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
  }

  .profile__menu {
    height: 50%;
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .profile__menu a,
  .profile_menu a:hover {
    text-decoration: none;
  }

  .profile__menu .profile--button {
    height: 100%;
    width: 100%;
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    background-color: #004a22;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
  }

  .profile--button h2 {
    color: #e2dfcf;
  }

  .journal--log--table {
    width: 80%;
    height: auto;
    background-color: #004a22;
    border-radius: 8px;
    padding: 24px
  }

  .journal--log--table--row {
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }

  .journal--log--table--row:nth-child(1) {
    border-radius: 8px 8px 0 0;

  }

  .journal--log--table--row:last-child() {
    border-radius: 0 0 8px 8px;

  }
  
  .table--row--buttons {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }

  .journal--log--table--row td
   {
   width: 50%; 
   display: flex; 
   justify-content: center;

  }

  .journal--log--table--row td, 
  .journal--log--table--row td a
   {
    color: #e2dfcf; 
    text-decoration: none;
  }

  .journal--log--table--row td:not(:first-child):hover, 
  .journal--log--table--row td:hover .journal--log--table--row td a
   {
    background-color: #e2dfcf;
    color: #004a22;
    cursor: pointer;
    border-radius: 8px;
  }
}
