@media (max-width: 1024px) {
  .blog__main {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .blog__canvas {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 24px;
  }

  .blog__content--mobile {
    width: 100%;
    color: #004a22;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border-top: 2px solid #004a22;
    border-bottom: 2px solid #004a22;
    padding: 18px;
  }

  .blog__image {
    width: 100%;
    padding: 12px;
    margin: 24px 0;
  }

  .blog__image img {
    border-radius: 5px;
    border: 2px solid #004a22;
  }

  .post__title {
    font-size:xx-large;
    text-align: start;
    font-weight: 600;
  }

  .blog_label {
    margin-bottom: 24px;
  }

  .blog__link {
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
  }

  .blog__blockquote .blog__link,
  li .blog__link {
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
  }

  .blog__link--italic {
    font-style: italic;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
  }

  .blog__blockquote .blog__link,
  li .blog__link--italic {
    font-style: italic;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
  }

  .blog__content.about {
    width: 100%;
    padding: 12px;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__toolbar {
    display: none;
  }

  .blog__toolbar a {
    display: none;
  }

  .blog__text--h1 {
    font-size: xx-large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--h4 {
    font-size: large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--p {
    font-size: large;
    font-weight: 600;
    margin: 6px 0;
  }

  .blog__blockquote {
    padding: 6px 6px 6px 12px;
    border-left: 2px solid #004a22;
  }

  .blog__blockquote span {
    font-size: large;
    font-weight: normal;
    font-style: italic;
  }

  .text--strong {
    font-weight: bold;
  }

  .blog__category--list {
    background-color: #004a22;
    height: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .blog__category--list h6 {
    color: #e2dfcf;
  }

  .blog__list {
    min-height: 100vh;
    height: auto;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    row-gap: 12px;
    column-gap: 6px;
    padding: 24px;
  }

  .blog__list--ul1,
  .blog__list--ul2 {
    height: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }

  /* .blog__list--ul1 li:first-child {
    margin-top: 24px;
    padding-top: 24px;
  } */

  .blog__list--ul1 li {
    padding: 6px 0;
    list-style: inside;
  }

  .blog__list--ul2 li {
    padding: 6px 0;
    list-style: square;
  }

  .blog__list--ul1 span,
  .blog__list--ul2 span,
  .blog__span {
    font-size: large;
  }

  .blog__span--strong {
    font-size: large;
    font-weight: bold;
  }

  .blog__category--list a {
    padding: 12px 0;
    color: #e2dfcf;
    font-size: 0.8em;
  }

  .select-style {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .option-style {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .blog__tile,
  .blog__tile--wide {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }

  .blog__tile--details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px 24px 24px;
  }

  .blog__tile--excerpt {
    font-size: medium;
  }

  .blog__tile--header {
    font-weight: 600;
    font-size: x-large;
    padding: 6px 0;
    margin-top: 24px;
    color: #004a22;
  }

  .blog__span--em {
    font-style: italic;
  }

  .blog__tile--date-cta {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 9px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .blog__tile--link {
    text-decoration: none;
  }

  .blog__block {
    display: block;
    font-size: large;
    text-align: justify;
    padding: 6px 0;
  }

  .blog__block--h3 {
    display: block;
    font-size: xx-large;
  }

  .blog__block--h4 {
    display: block;
    font-size: x-large;
    text-transform: uppercase;
  }

  .blog__span {
    width: 100%;
  }

  .post--image {
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #004a22;
  }

  .post--latest {
    background-color: #004a22;
    margin: 12px 0;
    padding: 24px;
    color: #e2dfcf;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .blog__main {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 0;
  }

  .blog__category--list {
    background-color: #004a22;
    border-radius: 0 5px 5px 0;
    min-height: 100vh;
    height: auto;
    width: 15%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  .blog__category--list a {
    padding: 6px 0;
    color: #e2dfcf;
  }

  .blog__list {
    min-height: 100vh;
    height: auto;
    width: 85%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 12px;
    column-gap: 12px;
    padding: 24px;
  }

  .blog__canvas {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 12px 12px 12px;
  }

  .blog__content {
    height: auto;
    width: 100%;
    padding: 24px;
    color: #004a22;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__content.about {
    width: 100%;
    padding: 12px;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__text--normal {
    font-style: normal;
    padding: 6px 0;
  }

  .blog__text--h1 {
    font-size: xx-large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--h4 {
    font-size: large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--p {
    font-size: large;
    font-weight: 600;
    padding: 6px 0;
  }

  .text--strong {
    font-weight: bold;
  }

  .blog__tile {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .blog__tile--wide {
    grid-column: auto / span 2;
    grid-row: auto / span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .blog__tile--details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px 24px 24px;
  }

  .blog__tile--details div {
    padding: 6px 0;
  }

  .blog__tile--header {
    font-weight: 600;
    font-size: large;
    padding: 6px 0;
    margin-top: 24px;
  }

  .blog__block--blockquote {
    border-left: 2px solid #004a22;
    text-indent: 10px;
    padding: 6px 0;
  }

  .blog__block--blockquote span {
    font-style: italic;
  }

  .post--image {
    border-bottom: 2px solid #004a22;
  }

  .blog__tile--excerpt {
    padding: 12px 0;
    font-size: 0.9em;
  }

  .blog__tile--date-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 9px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .blog__tile--link {
    text-decoration: none;
  }

  .blog__tile--wide .blog__tile--link {
    width: 50%;
  }

  .blog__tile .blog__tile--link {
    width: 100%;
  }

  .post--latest {
    min-height: 140px;
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: 600;
    padding: 24px;
    border-radius: 5px;
    margin: 12px 0;
  }

  .blog__toolbar {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    padding: 24px;
  }

  .blog__toolbar label {
    font-size: large;
  }

  .blog__toolbar a {
    color: #e2dfcf;
    text-decoration: none;
  }

  .blog__links {
    padding: 12px 0;
    width: 100%;
  }

  .blog__links a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    text-decoration: none;
  }

  .blog__links li {
    width: 100%;
    padding: 6px;
    list-style-type: none;
    background-color: #004a22;
    margin: 6px 0;
    border-radius: 5px;
  }

  .blog__social {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 6px;
  }

  .blog__social li {
    padding-right: 12px;
  }

  .blog__social a {
    color: #004a22;
  }

  .blog__block--h3 {
    display: block;
    font-size: xx-large;
    font-weight: bold;
  }

  .blog__block--h4 {
    display: block;
    font-size: x-large;
    font-weight: bold;
  }

  .blog__block--normal {
    display: block;
    font-size: normal;
  }

  .blog__span {
    width: 100%;
  }

  .blog__image {
    width: 100%;
    padding: 12px;
    margin: 24px 0;
  }

  .blog__image img {
    border-radius: 5px;
    border: 2px solid #004a22;
  }

  .blog_label {
    margin-bottom: 24px;
  }

  .blog__link {
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
  }

  .blog__blockquote .blog__link,
  li .blog__link {
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
  }

  .blog__link--italic {
    font-style: italic;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
  }

  .blog__blockquote .blog__link,
  li .blog__link--italic {
    font-style: italic;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
  }

  .blog__content.about {
    width: 100%;
    padding: 12px;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__text--h1 {
    font-size: xx-large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--h4 {
    font-size: large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--p {
    font-size: large;
    font-weight: 600;
    margin: 6px 0;
  }

  .blog__blockquote {
    padding: 6px 6px 6px 12px;
    border-left: 2px solid #004a22;
  }

  .blog__blockquote span {
    font-size: large;
    font-weight: normal;
    font-style: italic;
  }

  .text--strong {
    font-weight: bold;
  }

  .blog__category--list h6 {
    color: #e2dfcf;
  }

  .blog__list--ul1,
  .blog__list--ul2 {
    height: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }

  /* .blog__list--ul1 li:first-child {
    margin-top: 24px;
    padding-top: 24px;
  } */

  .blog__list--ul1 li {
    padding: 6px 0;
    list-style: inside;
  }

  .blog__list--ul2 li {
    padding: 6px 0;
    list-style: square;
  }

  .blog__list--ul1 span,
  .blog__list--ul2 span,
  .blog__span {
    font-size: large;
  }

  .blog__span--strong {
    font-size: large;
    font-weight: bold;
  }

  .select-style {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .option-style {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .blog__tile {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }

  .blog__tile--details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px 24px 24px;
  }

  .blog__tile--details div {
    padding: 6px 0;
  }

  .blog__tile--excerpt {
    font-size: 0.9em;
  }

  .blog__tile--header {
    font-weight: 600;
    font-size: 1.5em;
    padding: 6px 0;
    margin-top: 24px;
    color: #004a22;
  }

  .blog__span--em {
    font-style: italic;
  }

  .blog__tile--link {
    text-decoration: none;
  }

  .blog__block {
    display: block;
    font-size: large;
    text-align: justify;
    padding: 6px 0;
  }

  .blog__block--h3 {
    display: block;
    font-size: xx-large;
  }

  .blog__block--h4 {
    display: block;
    font-size: x-large;
    text-transform: uppercase;
  }

  .blog__span {
    width: 100%;
  }

  .post--image {
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #004a22;
  }
}

@media (min-width: 1201px) {
  .blog__main {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 0;
  }

  .blog__category--list {
    background-color: #004a22;
    border-radius: 0 5px 5px 0;
    min-height: 100vh;
    height: auto;
    width: 15%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  .blog__category--list a {
    padding: 6px 0;
    color: #e2dfcf;
  }

  .blog__list {
    min-height: 100vh;
    height: auto;
    width: 85%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(20, 1fr);
    row-gap: 12px;
    column-gap: 12px;
    padding: 24px;
  }

  .blog__canvas {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
  }

  .blog__content {
    height: auto;
    width: 100%;
    padding: 24px;
    color: #004a22;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__content.about {
    width: 100%;
    padding: 12px;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__text--normal {
    font-style: normal;
    padding: 6px 0;
  }

  .blog__text--h1 {
    font-size: xx-large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--h4 {
    font-size: large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--p {
    font-size: large;
    font-weight: 600;
    padding: 6px 0;
  }

  .text--strong {
    font-weight: bold;
  }

  .blog__tile {
    grid-column: auto / span 1;
    grid-row: auto / span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .blog__tile--wide {
    grid-column: auto / span 2;
    grid-row: auto / span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .blog__tile--details {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
  }

  .blog__tile--details div {
    padding: 6px 0;
  }

  .blog__tile--header {
    font-weight: 600;
    font-size: x-large;
    padding: 24px 0 12px 0;
  }

  .blog__block--blockquote {
    border-left: 2px solid #004a22;
    text-indent: 10px;
    padding: 6px 0;
  }

  .blog__block--blockquote span {
    font-style: italic;
  }

  .post--image {
    border-bottom: 2px solid #004a22;
  }

  .blog__tile--excerpt {
    padding: 12px 0;
    font-size: medium;
  }

  .blog__tile--date-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 9px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .blog__tile--link {
    text-decoration: none;
  }

  .blog__tile--wide .blog__tile--link {
    width: 50%;
  }

  .blog__tile .blog__tile--link {
    width: 100%;
  }

  .post--latest {
    min-height: 140px;
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: 600;
    padding: 24px;
    border-radius: 5px;
    margin: 12px 0;
  }

  .blog__toolbar {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    padding: 24px;
  }

  .blog__toolbar label {
    font-size: large;
  }

  .blog__toolbar a {
    color: #e2dfcf;
    text-decoration: none;
  }

  .blog__links {
    padding: 12px 0;
    width: 100%;
  }

  .blog__links a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    text-decoration: none;
  }

  .blog__links li {
    width: 100%;
    padding: 6px;
    list-style-type: none;
    background-color: #004a22;
    margin: 6px 0;
    border-radius: 5px;
  }

  .blog__social {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 6px;
  }

  .blog__social li {
    padding-right: 12px;
  }

  .blog__social a {
    color: #004a22;
  }

  .post__title {
    font-size:xx-large;
    text-align: start;
    font-weight: 600;
  }

  .blog__block {
    display: block;
    font-size: large;
    text-align: justify;
    padding: 6px 0;
  }

  .blog__block--h3 {
    display: block;
    font-size: xx-large;
    font-weight: bold;
  }

  .blog__block--h4 {
    display: block;
    font-size: x-large;
    font-weight: bold;
    text-align: start;
  }

  .blog__block--normal {
    display: block;
    font-size: normal;
  }

  .blog__span {
    width: 100%;
  }

  .blog__image {
    width: 100%;
    padding: 12px;
    margin: 24px 0;
  }

  .blog__image img {
    border-radius: 5px;
    border: 2px solid #004a22;
  }

  .blog_label {
    margin-bottom: 24px;
  }

  .blog__link {
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
  }

  .blog__blockquote .blog__link,
  li .blog__link {
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
  }

  .blog__link--italic {
    font-style: italic;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
  }

  .blog__blockquote .blog__link,
  li .blog__link--italic {
    font-style: italic;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
  }

  .blog__content.about {
    width: 100%;
    padding: 12px;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
  }

  .blog__text--h1 {
    font-size: xx-large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--h4 {
    font-size: large;
    font-weight: 600;
    padding: 12px 0 6px 0;
  }

  .blog__text--p {
    font-size: large;
    font-weight: 600;
    margin: 6px 0;
  }

  .blog__blockquote {
    padding: 6px 6px 6px 12px;
    border-left: 2px solid #004a22;
  }

  .blog__blockquote span {
    font-size: large;
    font-weight: normal;
    font-style: italic;
  }

  .text--strong {
    font-weight: bold;
  }

  .blog__category--list h6 {
    color: #e2dfcf;
  }

  .blog__list--ul1,
  .blog__list--ul2 {
    height: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }

  /* .blog__list--ul1 li:first-child {
    margin-top: 24px;
    padding-top: 24px;
  } */

  .blog__list--ul1 li {
    padding: 6px 0;
    list-style: inside;
  }

  .blog__list--ul2 li {
    padding: 6px 0;
    list-style: square;
  }

  .blog__list--ul1 span,
  .blog__list--ul2 span,
  .blog__span {
    font-size: large;
  }

  .blog__span--strong {
    font-size: large;
    font-weight: bold;
  }

  .select-style {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .option-style {
    background-color: #e2dfcf;
    color: #004a22;
  }

}
