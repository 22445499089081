@media (max-width: 480px) {
  #page-loading {
    margin: 100px;
  }

  .rectWeek {
    animation: 6s ease-in-out 0.1s animating-multiple-properties-week;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    margin: 12px 0;
    padding: 6px;
    opacity: 0;
  }

  .rectWeek span {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    font-style: italic;
  }

  .rectWeek__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  @keyframes animating-multiple-properties-week {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .rect {
    height: auto;
    width: auto;
    border-radius: 30px;
    background-color: #004a22;
    margin: 12px 12px 0px 0px;
    box-shadow:
      26px 26px 51px 0 rgba(0, 74, 35, 0.2) inset,
      -26px -26px 51px 0 rgba(0, 74, 35, 0.2) inset;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .rect span {
    padding-right: 24px;
    padding-left: 12px;
    font-weight: 600;
    font-size: 0.8em;
    text-decoration: none;
    font-style: italic;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      background-color: rgba(0, 74, 35, 0);
      color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      background-color: rgba(0, 74, 35, 0.8);
      color: rgba(0, 74, 35, 0.8);
    }
  }

  .dropdown,
  .dropdown:hover,
  .dropdown:active,
  .dropdown:focus,
  .dropdown:visited,
  .dropdown:link {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .tileLoading {
    min-height: 75vh;
    height: auto;
    min-width: 200px;
    max-width: 95%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    transition: all 1s ease-in-out;
  }

  .loading__wrapper{
    border-radius: 8px;
    animation: 12s ease-in-out infinite alternate growShrink;
    height: min-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading__container-v1 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  }

  .loading__container-v2 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: start;
  }

  .loading--icon {
    transform: translateY(-18px);
    height: 36px;
    width: 36px;
  }

  .loading--icon--memory {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 95%;
    top: 25%;
    transform: translateY(-6px);
  }

      .memory__div{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
      }
    

      /* 
      .tileLoading div:last-child {
        width: 32px;
        height: 32px;
      } */
    
      .tileLoading h2 {
        margin: 0 0 6px 0;
        padding: 0;
        color: #e2dfcf; 
        font-weight: 600;
      }

      .tileLoading p {
        margin: 0;
        padding: 0;
        color: #e2dfcf; 
        font-weight: 600;
      }

  @keyframes growShrink {
    0% {
      color: rgba(226, 223, 207,1);
      transform: scale3d(1, 1, 1);
    }
    25% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    50% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    75% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .blog__content--loading {
    min-height: 100px;
    height: auto;
    width: 100%;
    color: #004a22;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-top: 2px solid #004a22;
    border-bottom: 2px solid #004a22;
    animation: 1s ease-in-out 0.1s infinite alternate animating-multiple-props;
    transition: all 1s;
  }

  .blog__content--loading span {
    font-weight: 600;
    text-decoration: none;
    opacity: 1;
    font-size: 1.25em;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }

  @keyframes animating-multiple-props {
    from {
      opacity: 1;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(245, 245, 245, 1);
      height: 100px;
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(245, 245, 245, 1);
      height: 110px;
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #page-loading {
    margin: 100px;
  }

  .rectWeek {
    animation: 6s ease-in-out 0.1s animating-multiple-properties-week;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    margin: 12px 0;
    padding: 6px;
    opacity: 0;
  }

  .rectWeek span {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    font-style: italic;
  }

  .rectWeek__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  @keyframes animating-multiple-properties-week {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .rect {
    height: auto;
    width: auto;
    border-radius: 30px;
    background-color: #004a22;
    margin: 12px 12px 0px 0px;
    box-shadow:
      26px 26px 51px 0 rgba(0, 74, 35, 0.2) inset,
      -26px -26px 51px 0 rgba(0, 74, 35, 0.2) inset;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .rect span {
    padding-right: 24px;
    padding-left: 12px;
    font-weight: 600;
    font-size: 0.8em;
    text-decoration: none;
    font-style: italic;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      background-color: rgba(0, 74, 35, 0);
      color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      background-color: rgba(0, 74, 35, 0.8);
      color: rgba(0, 74, 35, 0.8);
    }
  }

  .dropdown,
  .dropdown:hover,
  .dropdown:active,
  .dropdown:focus,
  .dropdown:visited,
  .dropdown:link {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .tileLoading {
    height: auto;
    min-width: 200px;
    max-width: 95%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    transition: all 1s ease-in-out;
  }

      .loading__wrapper{
        border-radius: 8px;
        animation: 12s ease-in-out infinite alternate growShrink;
        height: min-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
      .loading__container-v1 {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        padding: 16px 20px;
        background-color: #004a22;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
      }
    
      .loading__container-v2 {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        padding: 16px 20px;
        background-color: #004a22;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: start;
      }
    
      .loading--icon {
        transform: translateY(-18px);
        height: 36px;
        width: 36px;
      }
    
      .loading--icon--memory {
        height: 36px;
        width: 36px;
        position: absolute;
        left: 95%;
        top: 25%;
        transform: translateY(-6px);
      }

      .memory__div{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
      }
    
 
      /* 
      .tileLoading div:last-child {
        width: 32px;
        height: 32px;
      } */
    
      .tileLoading h2 {
        margin: 0 0 6px 0;
        padding: 0;
        color: #e2dfcf; 
        font-weight: 600;
      }

      .tileLoading p {
        margin: 0;
        padding: 0;
        color: #e2dfcf; 
        font-weight: 600;
      }

  @keyframes growShrink {
    0% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    25% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    50% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    75% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .blog__content--loading {
    min-height: 100px;
    height: auto;
    width: 100%;
    color: #004a22;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-top: 2px solid #004a22;
    border-bottom: 2px solid #004a22;
    animation: 1s ease-in-out 0.1s infinite alternate animating-multiple-props;
    transition: all 1s;
  }

  .blog__content--loading span {
    font-weight: 600;
    text-decoration: none;
    opacity: 1;
    font-size: 1.25em;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }

  @keyframes animating-multiple-props {
    from {
      opacity: 1;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(245, 245, 245, 1);
      height: 100px;
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(245, 245, 245, 1);
      height: 110px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .loading__wrapper {
    border-radius: 8px;
    animation: 12s ease-in-out infinite alternate growShrink;
    height: min-content;
    min-width: 200px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading__container-v1 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  }

  .loading__container-v2 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: start;
  }

  .loading--icon {
    transform: translateY(-18px);
    height: 36px;
    width: 36px;
  }

  .loading--icon--memory {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 95%;
    top: 25%;
    transform: translateY(-6px);
  }


  .memory__div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  /* 
  .tileLoading div:last-child {
    width: 32px;
    height: 32px;
  } */

  .tileLoading h2 {
    margin: 0 0 6px 0;
    padding: 0;
    color: #e2dfcf; 
    font-weight: 600;
  }

  .tileLoading p {
    margin: 0;
    padding: 0;
    color: #e2dfcf; 
    font-weight: 600;
  }

  .rectWeek {
    animation: 6s ease-in-out 0.1s animating-multiple-properties-week;
    height: 6vh;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    margin: 24px 12px 12px 12px;
    padding: 6px;
    opacity: 0;
  }

  .rectWeek span {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    font-style: italic;
  }

  .rectWeek__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  @keyframes animating-multiple-properties-week {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #page-loading {
    margin: 100px;
  }

  .rect {
    height: auto;
    width: auto;
    border-radius: 30px;
    background-color: #004a22;
    margin: 12px 12px 0px 0px;
    box-shadow:
      26px 26px 51px 0 rgba(0, 74, 35, 0.2) inset,
      -26px -26px 51px 0 rgba(0, 74, 35, 0.2) inset;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .rect span {
    padding-right: 24px;
    padding-left: 12px;
    font-weight: 600;
    font-size: 1.5em;
    text-decoration: none;
    font-style: italic;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      background-color: rgba(0, 74, 35, 0);
      color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      background-color: rgba(0, 74, 35, 0.8);
      color: rgba(0, 74, 35, 0.8);
    }
  }

  .dropdown,
  .dropdown:hover,
  .dropdown:active,
  .dropdown:focus,
  .dropdown:visited,
  .dropdown:link {
    background-color: transparent;
    outline: none;
    border: none;
  }


  @keyframes growShrink {
    0% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    25% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    50% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    75% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .blog__content--loading {
    min-height: 100px;
    width: 100%;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-top: 2px solid #004a22;
    border-bottom: 2px solid #004a22;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
      padding: 12px;
    transition: all 1s;
  }

  .blog__content--loading span {
    font-weight: 600;
    font-size: large;
    text-decoration: none;
    font-style: italic;
    opacity: 1;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .rectWeek {
    animation: 6s ease-in-out 0.1s animating-multiple-properties-week;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    margin: 24px 12px 12px 12px;
    padding: 6px;
    opacity: 0;
  }

  .rectWeek span {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    font-style: italic;
  }

  .rectWeek__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  @keyframes animating-multiple-properties-week {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .rect {
    height: auto;
    width: auto;
    border-radius: 30px;
    background-color: #004a22;
    margin: 12px 12px 0px 0px;
    box-shadow:
      26px 26px 51px 0 rgba(0, 74, 35, 0.2) inset,
      -26px -26px 51px 0 rgba(0, 74, 35, 0.2) inset;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .rect span {
    padding-right: 24px;
    padding-left: 12px;
    font-weight: 600;
    font-size: 0.8em;
    text-decoration: none;
    font-style: italic;
    opacity: 0;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 0);
      background-color: transparent;
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }

  .dropdown,
  .dropdown:hover,
  .dropdown:active,
  .dropdown:focus,
  .dropdown:visited,
  .dropdown:link {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .tileLoading {
    height: auto;
    min-width: 200px;
    max-width: 50%;
    background-color: #004a22;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    animation: 12s ease-in-out infinite alternate growShrink;
  }

  .tileLoading div {
    width: 100%;
  }

  /* .tileLoading div:last-child {
    width: 32px;
    height: 32px;
  } */

  .tileLoading h,
  .tileLoading p {
    margin: 0;
    padding: 0;
  }

  @keyframes growShrink {
    0% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    25% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    50% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    75% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .blog__content--loading {
    min-height: 100px;
    width: 100%;
    color: #004a22;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .blog__content--loading span {
    font-weight: 600;
    font-size: 0.8em;
    text-decoration: none;
    font-style: italic;
    opacity: 1;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }

  .loading__wrapper {
    border-radius: 8px;
    animation: 12s ease-in-out infinite alternate growShrink;
    height: min-content;
    min-width: 200px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading__container-v1 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  }

  .loading__container-v2 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: start;
  }

  .loading--icon {
    transform: translateY(-18px);
    height: 36px;
    width: 36px;
  }

  .loading--icon--memory {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 95%;
    top: 25%;
    transform: translateY(-6px);
  }
}

@media (min-width: 1201px) {
  .rectWeek {
    animation: 6s ease-in-out 0.1s animating-multiple-properties-week;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    margin: 24px 12px 12px 12px;
    padding: 6px;
    opacity: 0;
  }

  .rectWeek span {
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    font-style: italic;
  }

  .rectWeek__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  @keyframes animating-multiple-properties-week {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .rect {
    height: auto;
    width: auto;
    border-radius: 30px;
    background-color: #004a22;
    margin: 12px 12px 0px 0px;
    box-shadow:
      26px 26px 51px 0 rgba(0, 74, 35, 0.2) inset,
      -26px -26px 51px 0 rgba(0, 74, 35, 0.2) inset;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .rect span {
    padding-right: 24px;
    padding-left: 12px;
    font-weight: 600;
    font-size: 0.8em;
    text-decoration: none;
    font-style: italic;
    opacity: 0;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 0);
      background-color: transparent;
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }

  .dropdown,
  .dropdown:hover,
  .dropdown:active,
  .dropdown:focus,
  .dropdown:visited,
  .dropdown:link {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .tileLoading {
    min-height: 75vh;
height: 100%;
width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
  }

  .loading__wrapper {
    border-radius: 8px;
    animation: 12s ease-in-out infinite alternate growShrink;
    height: min-content;
    min-width: 200px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading__container-v1 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  }

  .loading__container-v2 {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background-color: #004a22;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: start;
  }

  .loading--icon {
    transform: translateY(-18px);
    height: 36px;
    width: 36px;
  }

  .loading--icon--memory {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 95%;
    top: 25%;
    transform: translateY(-6px);
  }
  /* 
  .tileLoading div:last-child {
    width: 32px;
    height: 32px;
  } */

  .tileLoading h2,
  .tileLoading p {
    margin: 0;
    padding: 0;
    color: #e2dfcf; 
    font-weight: 600;
  }

  .memory__div{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @keyframes growShrink {
    0% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    25% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    50% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
    75% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
      color: rgba(226, 223, 207, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .blog__content--loading {
    min-height: 100px;
    width: 100%;
    color: #004a22;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    animation: 1s ease-in-out 0.1s infinite alternate
      animating-multiple-properties;
    transition: all 1s;
  }

  .blog__content--loading span {
    font-weight: 600;
    font-size: 0.8em;
    text-decoration: none;
    font-style: italic;
    opacity: 1;
  }

  @keyframes animating-multiple-properties {
    from {
      opacity: 0;
      color: rgba(0, 74, 35, 1);
      background-color: rgba(0, 74, 35, 0);
    }
    to {
      opacity: 1;
      color: rgba(0, 74, 35, 0.75);
      background-color: rgba(0, 74, 35, 0.75);
    }
  }
}
