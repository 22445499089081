@media (max-width: 480px) {

  .objective__col {
    min-height: min-content;
    height: 75vh;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 6px;
    background-color: rgba(0, 74, 35, 0.1);
    border-radius: 5px;
    margin-bottom: 24px;
    overflow-y: hidden;
  }

  .objective__col.day--mobile {
    background-color: #e2dfcf;
    border: 2px solid rgba(0, 74,34,1);
  }

  .objective__col.activeCol {
    display: flex;
  }

  .objective__col:not(.activeCol) {
    display: none;
  }

  .hideColumn {
    opacity: 0.5;
  }

  .objective__colmobile {
    height: min-content;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    background-color: #e2dfcf;
    box-shadow: 0 10px 10px rgba(0, 74,34, 0.2);
    border-radius: 8px;
    border: 4px solid #004a22;
    position: fixed;
    top: 15%;
    left: 5%;
    z-index: 10000;
    overflow: none;
  }

  .objective__col.disabled {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    opacity: 1;
  }

  .objective__col.inactive {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    opacity: 0.1;
  }

  /* Objective Name */
  .objective__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
  }

  .objective__col--name:hover .col__name--icon {
    display: none;
  }

  .col__name--trash {
    display: none;
  }

  .col__name--hand {
    display: none;
  }

  .objective__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.ellipsis {
    color: #004a22;
    padding: 6px 9px 6px 12px;
    transform: scale(1.5);
  }

  .objective__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.trash:hover {
    color: #004a22;
    cursor: pointer;
  }

  .col__name--input {
    padding: 12px 6px;
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    box-shadow: none;
    color: #e2dfcf;
    font-weight: bold;
    font-size: medium;
  }

  .col__name--input.upper {
    font-size: large;
  }

  .col__name--input::placeholder {
    color: #e2dfcf;
    font-weight: bold;
    opacity: 0.7;
  }

  .col__name--icon {
    height: 36px;
    width: 36px;
    margin-right: 6px;
  }

  .objective__col--wrapper {
    width: 100%;
    display: flex;
  }

  .reorder {
    display: none;
  }

  /* SubTask */
  .objective__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
  }

  .objective__col--task {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;

  }

  .objective__col--task-active {
    opacity: 0.5;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
  }

  .task__container {
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .task--input {
    width: 100%; 
    /* background-color: rgba(0, 74, 35, 0); */
    border: none;
    outline: none;
    box-shadow: 2px 2px solid rgba(0, 74, 35, 0.5);
    color: #004a22;
    font-weight: bold;
    resize: none;
    font-size: medium;
    text-decoration: none;
    padding: 2px 6px;
  }

  .task--input.add {
    min-height: 48px;
    height: auto;
    max-height: 72px;
    padding: 6px 12px;
    background-color: #004a22;
    border-radius: 5px;
    text-decoration: none;
  }

  .task--input.completed {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: rgba(0, 74, 35, 0.5);
    font-weight: bold;
    text-decoration: solid line-through #004a22 2px;
  }

  /* Objective Callout  */
  .objective__callout {
    display: none;
  }

  .objective__callout--active {
    width: 90%;
    height: auto;
    max-height: 75vh;
    display: grid;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000; /* Make sure it appears above other elements */
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
    overflow: scroll;
  }

  /* .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
  }

  .callout__header--input {
    background-color: #f5f5f5;
    border: none;
    height: 100%; 
    width: 90%;
    font-size: x-large;
    font-weight: bold;
    text-indent: 12px;
    outline: none;
    }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
    resize: none;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  /* .callout__actions {
    grid-column: 1 / 11;
    grid-row: 3 / 8;
    border-top: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
  } */

  .callout__notes {
    grid-column: 1 / 11;
    grid-row: 8 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .notes {
    width: 100%;
    height: 80%;
    border: 2px solid #004a22;
    padding: 6px;
    border-radius: 5px;
    font-size: large;
  }

  /* .callout__button {
    height: auto;
    width: fit-content;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 12px;
    padding: 5px 6px;
    font-size: 0.7em;
    font-size: bold;
  }

  .callout__button:hover {
    cursor: pointer;
  } */

  /* Objective Callout  */
  .warning__callout {
    display: none;
  }

  .warning__callout--active {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 101;
    top: 20%;
    left: 20%;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 12px;
    text-align: center;
    color: #004a22;
  }

  .warning__button--row {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .warning__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .warning__button--row div:first-child {
    font-weight: bold;
  }

   /* Column Styling */
   .obj__col {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    margin: 12px 0;
  }

  .obj__col.disabled {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    opacity: 1;
  }

  /* obj Name */
  .obj__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
  }

  .obj__col--name .col__name--icon {
    display: none;
  }

  .obj__col--name .col__name--trash {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin-right: 12px;
  }

  .obj__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .obj__col--icon.ellipsis {
    padding: 6px 6px 6px 12px;
    color: #004a22;
  }

  .obj__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  /* SubTask */
  .obj__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
  }

  .obj__col--task {
    /* height: auto; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
    overflow-y: scroll;
  }

  .obj__col--task-active {
    opacity: 0.5;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
  }

 

  .col__name--close {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin-right: 12px;
  }

 

  /* obj Callout  */
  .obj__callout {
    display: none;
  }

  .obj__callout--active {
    width: 100%;
    height: auto;
    display: grid;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 5%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / span 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
  }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  .callout__actions {
    grid-column: 1 / 11;
    grid-row: 2 / 8;
    border-top: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
    overflow-y: scroll;
  }

  .callout__notes {
    grid-column: 1 / 11;
    grid-row: 8 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .callout__button {
    height: auto;
    width: fit-content;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    padding: 6px 8px;
    font-weight: bold;
    color: #004a22;
    margin-top: 12px;
  }

  /* obj Callout  */
  .warning__callout {
    display: none;
  }

  .warning__callout--active {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 101;
    top: 20%;
    left: 20%;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 12px;
    text-align: center;
    color: #004a22;
  }

  .warning__button--row {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .warning__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .warning__button--row div:first-child {
    font-weight: bold;
  }

  /* obj Callout  */
  .moment__callout {
    display: none;
  }

  .moment__callout--active {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 25;
    top: 22.5%;
    left: 3.5%;
    border-radius: 5px;
    border: 2px solid #004a22;
    color: #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }

  .moment__button--row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .moment__button--row .callout__button {
    height: 48px;
    width: 90%;
    font-weight: bold;
    text-align: center;
    color: #004a22;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .moment__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .task__column--wrapper {
    width: 15%;
    height: 100%;
    background-color: #004a22;
    display: flex;
    justify-content: center;
  }

  .task__column {
    width: 100%; 
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #004a22;
  }

  .task__icon--wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task__icon--wrapper div:not(task--radio) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
  }


  .row--icon {
    color: #e2dfcf;
    height: 60%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  } 

  .row--icon.calendar {
   margin-bottom: 4px;
  } 

  .task__radio {
    height: 62%;
    width: 62%;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
  }

  .task__radio--active {
    height: 60%;
    width: 60%;
    border-radius: 50%;
    background-color: #004a22;
    cursor: pointer;
    border: 2px solid #e2dfcf;
  }

  .task__row {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    background-color: #fff
  }

  .row--item--date {
    width: 100%;
    height: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 2;
    display: flex;
align-items: flex-end;
  }

  .fucksake,
  .fucksake:focus {
    min-height: 24px;
    height: auto;
    max-height: 36px;
    background-color: #e2dfcf;
    border-right: 2px solid #004a22;
    border-top: 2px solid #004a22;
    border-radius: 0 5px 0 0;
    color: #004a22; 
    appearance: none; 
    outline: none; 
    box-shadow: none;
    border-bottom: none;
    border-left: none;
    padding-left: 3px;
    width: auto;
    min-width: 25%;
  }

  .fucksake::-webkit-calendar-picker-indicator {
    filter: invert(45%) sepia(27%) saturate(167%) hue-rotate(100deg) brightness(85%) contrast(85%);
    cursor: pointer; 
  }

  .row--item--urgency {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    grid-row: 1 / 1;
grid-column: 2 / 2;
  }



  .urgency__grid--disabled {
    height: 48px; 
    width: 100%; 
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 3px 3px;
    padding: 3px;
    background-color: #e2dfcf;
    border-left: 2px solid #004a22;
    border-top: 2px solid #004a22;
    border-radius: 5px 0 0 0;
  }  
  
  .urgency__grid--active {
    height: 24px; 
    width: 50%; 
    display: grid; 
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 3px;
    background-color: #e2dfcf;
    border-left: 2px solid #004a22;
    border-top: 2px solid #004a22;
    border-radius: 5px 0 0 0;
  }

  .urgency__input {
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
    border-radius: 5px;
  }

  .urgency__input:hover {
 opacity: 0.75;
  }

  .board--scale {
    width: 100%;
    z-index: 101; 
  }

  .objective__sort--wrapper {
    width: 100%; 
    display: flex;
    padding: 0 0 12px 12px;
  }

  .sort--tool {
    width: 25%;
    display: grid;
    background-color: #004a22;
    color: #e2dfcf;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-radius: 20px ;
  }

  .sort--tool span svg {
    height: 18px;
    width: auto;
  }

  .urge {
    grid-column: 2 / 2;
    grid-row: 1 / 1 ;
    /* border-right: 2px solid #e2dfcf; */
    display: flex; 
    justify-content: space-around;
    align-items: center;
    font-size: 10px;
    border-radius: 0 20px 20px 0 ;
    padding: 5px 4px 4px 4px;
  }

  .urge:hover {
    cursor: pointer;
  }

  .on {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .dead {
    grid-column: 1 / 1;
    grid-row: 1 /1 ;
    border-right: 2px solid #e2dfcf;
    display: flex; 
    justify-content: space-around;
    align-items: center;
    font-size: 10px;
    border-radius: 20px 0 0 20px;
    padding: 4px;
  }

  .cust {
    grid-column: 3 / 3;
    grid-row: 1 /1 ;
    display: flex; 
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }

  .quotelist--container {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Column Styling */
  .objective__col {
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 6px;
    margin: 12px 12px;
    background-color: rgba(0, 74, 35, 0.1);
    border-radius: 5px;
  }

  .objective__col.disabled {
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
    opacity: 0.5;
  }

  /* Objective Name */
  .objective__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
  }

  .objective__col--name:hover .col__name--icon {
    display: none;
  }

  .col__name--trash {
    display: none;
  }

  .objective__col--name:hover .col__name--trash {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin-right: 12px;
  }

  .objective__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.ellipsis {
    padding: 6px 6px 6px 12px;
    color: #004a22;
  }

  .objective__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.trash:hover {
    color: #004a22;
    cursor: pointer;
  }

  .col__name--input {
    padding: 12px 6px;
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    box-shadow: none;
    color: #e2dfcf;
    font-weight: bold;
    font-size: 1.1em;
  }

  .col__name--input::placeholder {
    color: #e2dfcf;
    font-weight: bold;
    opacity: 0.7;
  }

  .col__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  .objective__col--wrapper {
    width: 100%;
    display: flex;
  }

  .reorder {
    display: none;
  }

  /* SubTask */
  .objective__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px 0;
  }

  .objective__col--task {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
  }

  .objective__col--task-active {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
    opacity: 0.5;
  }

  .task__icon--wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task__icon--wrapper div:not(task--radio) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task__radio {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    border: 1px solid #004a22;
    background-color: #e2dfcf;
    margin: 4px 6px;
  }

  .task__radio:hover,
  .task__radio--active {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    padding: 1px;
    background-color: #004a22;
    margin: 4px 6px;
    cursor: pointer;
  }

  .task--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1em;
  }

  .task--input.add {
    padding: 6px 12px;
  }

  .task--input.completed {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: rgba(0, 74, 35, 0.5);
    font-weight: bold;
    font-size: 1em;
    text-decoration: solid line-through #004a22 2px;
  }

  /* Objective Callout  */
  .objective__callout {
    display: none;
  }

  .objective__callout--active {
    width: 90%;
    height: 60vh;
    display: grid;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 5%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  /* .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
  }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
    resize: none;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  /* .callout__actions {
    grid-column: 1 / 11;
    grid-row: 3 / 8;
    border-top: 2px solid #004a22;
    border-right: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
  } */

  .callout__notes {
    grid-column: 1 / 11;
    grid-row: 8 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .notes {
    width: 100%;
    height: 100%;
    border: 2px solid #004a22;
    padding: 6px;
    border-radius: 5px;
    font-size: 1em;
  }

  /* .callout__button {
    height: auto;
    width: fit-content;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 12px;
    padding: 10px 12px;
  }

  .callout__button:hover {
    cursor: pointer;
  } */

  /* Objective Callout  */
  .warning__callout {
    display: none;
  }

  .warning__callout--active {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 20px;
    text-align: center;
    color: #004a22;
  }

  .warning__button--row {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .warning__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .warning__button--row div:first-child {
    font-weight: bold;
  }
   /* Column Styling */
   .obj__col {
    height: 100%;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
  }

  .obj__col.disabled {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
    opacity: 0.5;
  }

  .obj__col:hover {
    opacity: 1;
  }

  /* obj Name */
  .obj__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
  }

  .obj__col--name .col__name--icon {
    display: none;
  }

  .obj__col--name .col__name--trash {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin-right: 12px;
  }

  .obj__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .obj__col--icon.ellipsis {
    padding: 6px 6px 6px 12px;
    color: #004a22;
  }

  .obj__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .obj__col--icon.trash:hover {
    color: #004a22;
    cursor: pointer;
  }

  .col__name--input {
    padding: 12px 6px;
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    box-shadow: none;
    color: #e2dfcf;
    font-weight: bold;
    font-size: 1.1em;
  }

  .col__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  /* SubTask */
  .obj__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
  }

  .obj__col--task {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
  }

  .obj__col--task:hover {
    background-color: #004a22;
    border: 2px solid #f5f5f5;
    cursor: pointer;
  }

  .task__radio {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    border: 1px solid #004a22;
    background-color: #e2dfcf;
    margin: 4px 6px;
  }

  .task__radio:hover,
  .task__radio--active {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    padding: 1px;
    background-color: #004a22;
    margin: 4px 6px;
    cursor: pointer;
  }

  /* obj Callout  */
  .obj__callout {
    display: none;
  }

  .obj__callout--active {
    min-width: 388px;
    max-width: 456px;
    height: auto;
    display: grid;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 5%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
  }

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
  }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  .callout__actions {
    grid-column: 1 / 11;
    grid-row: 3 / 8;
    border-top: 2px solid #004a22;
    border-right: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
    overflow-y: scroll;
  }

  .callout__notes {
    grid-column: 1 / 11;
    grid-row: 8 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .notes {
    width: 100%;
    height: 100%;
    border: 2px solid #004a22;
    padding: 6px;
    border-radius: 5px;
    font-size: 1em;
  }

  .callout__button {
    height: auto;
    width: fit-content;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 12px;
    padding: 10px 12px;
  }

  .callout__button:hover {
    cursor: pointer;
  }

  /* obj Callout  */
  .moment__callout {
    display: none;
  }

  .moment__callout--active {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    border-radius: 5px;
    border: 2px solid #004a22;
    color: #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }

  .moment__button--row {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .moment__button--row .callout__button {
    font-weight: bold;
  }

  .moment__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .quotelist--container {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}


@media (min-width: 768px) and (max-width: 1999px) {
  /* Column Styling */
  .objective__col {
    height: min-content;
    max-height: 750px;
    background-color: rgba(0, 74, 35, 0.1);
    min-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 6px;
    margin: 12px;
    border-radius: 5px;
  }

  .objective__col.activeCol {
    display: flex;
  }

  .objective__col.inactiveCol {
    display: none;
  }

  .hideColumn {
    opacity: 0.5;
  }

  .hideColumn:hover {
    opacity: 0.5;
  }

  .objective__col:hover {
    opacity: 1;
  }

  /* Objective Name */
  .objective__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    margin-bottom: 12px;
  }

  .objective__col--name:last-child {
    margin-top: 12px;
  }

  .objective__col--name:hover .col__name--icon {
    display: none;
  }

  .col__name--trash {
    display: none;
  }

  .objective__col--name:hover .col__name--trash {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin-left: 12px;
  }

  .objective__sort--wrapper {
    width: 100%; 
    display: flex;
  }

  .sort--tool {
    width: 25%;
    display: grid;
    background-color: #004a22;
    color: #e2dfcf;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-radius: 20px ;
  }

  .urge {
    grid-column: 2 / 2;
    grid-row: 1 / 1 ;
    border-right: 2px solid #e2dfcf;
    display: flex; 
    justify-content: space-around;
    align-items: center;
    font-size: 10px;
    border-radius: 0 20px 20px 0 ;
    padding: 2px 4px;
  }

  .urge:hover {
    cursor: pointer;
  }

  .on {
    background-color: #e2dfcf;
    color: #004a22;
  }

  .dead {
    grid-column: 1 / 1;
    grid-row: 1 /1 ;
    border-right: 2px solid #e2dfcf;
    display: flex; 
    justify-content: space-around;
    align-items: center;
    font-size: 10px;

    border-radius: 20px 0 0 20px;
    padding: 2px 4px;
  }

  .cust {
    grid-column: 3 / 3;
    grid-row: 1 /1 ;
    display: flex; 
    justify-content: center;
    align-items: center;

    font-size: 10px;
  }

  .objective__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.ellipsis {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    /* padding: 6px 6px 6px 6px; */
    /* border-radius: 12px 0px 0px 12px; */
    color: #004a22;
  }

  .objective__col--icon.ellipsis:hover {
    padding: 6px 6px 6px 12px;
    background-color: #004a22;
    color: #e2dfcf;

  }

  .objective__col--icon.ellipsis:hover {
    cursor: pointer;
  }

  .objective__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.trash:hover {
    color: #004a22;
    cursor: pointer;
  }

  .col__name--input {
    padding: 12px 6px;
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    box-shadow: none;
    color: #e2dfcf;
    font-weight: bold;
    font-size: 1.1em;
  }

  .col__name--input::placeholder {
    color: #e2dfcf;
    font-weight: bold;
    opacity: 0.7;
  }

  .col__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-left: 6px;
  }

  .objective__col--wrapper {
    width: 100%;
    /* z-index: 1000; */
    margin: 4px 0;
  }

  .objective__col--wrapper:first-child {
    width: 100%;
    margin: 0 0 4px 0;
  }

  .reorder {
    display: none;
  }

  /* .objective__col--wrapper:hover .objective__col--task {
    background-color: #004a22;
  } */

  /* .objective__col--wrapper:hover .task--input,
  .objective__col--wrapper:hover #subTaskInput,
  .objective__col--wrapper:hover .objective__col--icon.ellipsis {
    color: #e2dfcf;
  } */

  .objective__col--wrapper:hover .reorder {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .objective__col--reorder {
    padding: 1px 0px 1px 4px;
    margin: 0;
    opacity: 0.125;
  }

  .objective__col--reorder:hover {
    opacity: 1;
    cursor: pointer;
  }

  /* SubTask */
  .objective__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px 0;
  }

  .objective__col--task {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
    /* padding: 2px 0; */
    flex-grow: 1;
    flex-basis: 100%;
  }

  .objective__col--task-active {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #004a22;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
    opacity: 0.5;
    flex-grow: 1;
    flex-basis: 100%;
  }

  .task__icon--wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
  }

  .task__icon--wrapper div:not(task--radio) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task__radio {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    border: 1px solid #004a22;
    background-color: #e2dfcf;
    margin: 4px 6px;
  }

  .task__radio:hover,
  .task__radio--active {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    padding: 1px;
    background-color: #004a22;
    margin: 4px 6px;
    cursor: pointer;
  }

  .task--input {
    width: 100%; 
    border: none;
    outline: none;
    box-shadow: 2px 2px solid rgba(0, 74, 35, 0.5);
    color: #004a22;
    font-weight: bold;
    resize: none;
    font-size: 1em;
    text-decoration: none;
    padding: 2px 3px;
  }

  .task--input.add {
    min-height: 48px;
    height: auto;
    max-height: 72px;
    padding: 6px 12px;
    background-color: #004a22;
    border-radius: 5px;
    text-decoration: none;
  }

  .task--input.completed {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: rgba(0, 74, 35, 0.5);
    font-weight: bold;
    font-size: 1em;
    text-decoration: solid line-through #004a22 2px;
  }

  /* Objective Callout  */
  .objective__callout {
    display: none;
  }

  .objective__callout--active {
    min-width: 600px;
    width: auto;
    min-height: 500px;
    height: auto;
    display: grid;
    position: absolute;
    z-index: 100;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 50px);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  /* .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
  }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
    resize: none;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  /* .callout__actions {
    grid-column: 1 / 5;
    grid-row: 3 / 11;
    border-top: 2px solid #004a22;
    border-right: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
  } */

  .callout__notes {
    grid-column: 5 / 11;
    grid-row: 3 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .notes {
    width: 100%;
    height: 80%;
    border: 2px solid #004a22;
    padding: 6px;
    border-radius: 5px;
    resize: none;
  }

  /* Objective Callout  */
  .warning__callout {
    display: none;
  }

  .warning__callout--active {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }

  .warning__button--row {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .warning__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .warning__button--row div:first-child {
    font-weight: bold;
  }

  .objective__col.inactive {
    opacity: 0.1;
  }
 
   /* Column Styling */
   .obj__col {
    height: 100%;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
    cursor: grab;
  }

  .obj__col.disabled {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
    opacity: 0.5;
  }

  .obj__col:hover {
    opacity: 1;
  }

  /* obj Name */
  .obj__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 74, 34, 1);
    border-radius: 5px;
    transition: background-color 3s ease-in-out;
  }

  .obj__col--name.saving {
    background-color: rgba(103, 36, 131, 1);
    transition: background-color 3s ease-in-out;
  }

  .obj__col--name:hover .col__name--icon {
    display: none;
  }

  .col__name--trash {
    display: none;
  }

  .col__name--hand {
    display: none;
  }

  .objective__col--name:hover .col__name--trash,
  .objective__col--name:hover .col__name--hand
   {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin: 0 12px;
  }

  .objective__col--name:active {
    cursor: grabbing;
  }

  .obj__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
    cursor: pointer;
  }

  .obj__col--icon.ellipsis {
    padding: 6px 6px 6px 12px;
    color: #004a22;
  }

  .obj__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
    cursor: pointer;
  }

  .obj__col--icon.trash:hover {
    color: #004a22;
    cursor: pointer;
  }

  .col__name--input {
    padding: 12px 6px;
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    box-shadow: none;
    color: #e2dfcf;
    font-weight: bold;
    font-size: 1.1em;
  }

  .col__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  /* SubTask */
  .obj__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
  }

  /* .obj__col--subTasks:hover {
  } */

  .obj__col--task {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
  }

  .obj__col--task:hover {
    background-color: #004a22;
    border: 2px solid #f5f5f5;
    cursor: pointer;
  }

  .obj__col--task:hover .task--input,
  .obj__col--task:hover .obj__col--icon.ellipsis {
    color: #e2dfcf;
    cursor: pointer;
  }

  .obj__col--task-active {
    opacity: 0.5;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
  }

  .task__radio {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    border: 1px solid #004a22;
    background-color: #e2dfcf;
    margin: 4px 6px;
  }

  .task__radio:hover,
  .task__radio--active {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    padding: 1px;
    background-color: #004a22;
    border: 1px solid #e2dfcf;
    margin: 4px 6px;
    cursor: pointer;
  }

  .elip {
    margin: 2px 4px;
  }

  .task__container {
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* obj Callout  */
  .obj__callout {
    display: none;
  }

  .obj__callout--active {
    min-width: 612px;
    height: 60vh;
    display: grid;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    cursor: grab;
  }

  .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .callout__header--input {
background-color: #f5f5f5;
border: none;
height: 100%; 
width: 90%;
font-size: x-large;
font-weight: bold;
text-indent: 12px;
outline: none;
    }

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
  }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
    resize: none;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  .callout--icon:hover {
    cursor: pointer;
  }

  .callout__actions {
    grid-column: 1 / 5;
    grid-row: 3 / 11;
    border-top: 2px solid #004a22;
    border-right: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }

  .callout__notes {
    grid-column: 5 / 11;
    grid-row: 3 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .notes {
    width: 100%;
    height: 80%;
    border: 2px solid #004a22;
    padding: 6px;
    border-radius: 5px;
  }

  .callout__button {
    height: auto;
    width: fit-content;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 12px;
    padding: 10px 12px;
  }

  .callout__button:hover {
    cursor: pointer;
  }

  /* obj Callout  */
  .moment__callout {
    display: none;
  }

  .moment__callout--active {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    border-radius: 5px;
    border: 2px solid #004a22;
    color: #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }

  .moment__button--row {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .moment__button--row .callout__button {
    font-weight: bold;
  }

  .moment__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .col__name--close {
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin-right: 12px;
  }

  .objective__col.hideColumn{
    opacity: 0.5;
  }

  .task__column--wrapper {
    width: 15%;
    height: 100%;
    background-color: #004a22;
    padding: 2px 0;
    display: flex;
    justify-content: center;
  }


  .task__column {
    /* min-width: 20%; */
    width: auto; 
    height: 100%;
    min-height: 24px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
  }

  .task__row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

  }

  .row--item--date {
    width: 100%;
    display: flex;
    grid-row: 1 / 1;
    grid-column: 1 / 2;
align-items: flex-end;
  }

  .fucksake,
  .fucksake:focus {
    height: 100%;
    background-color: #e2dfcf;
    border-right: 2px solid #004a22;
    border-top: 2px solid #004a22;
    border-radius: 0 5px 0 0;
    color: #004a22; 
    appearance: none; 
    outline: none; 
    box-shadow: none;
    border-bottom: none;
    border-left: none;
    padding-left: 3px;
  }

  .fucksake::-webkit-calendar-picker-indicator {
    filter: invert(45%) sepia(27%) saturate(167%) hue-rotate(100deg) brightness(85%) contrast(85%);
    cursor: pointer; 
  }

  .row--item--urgency {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    grid-row: 1 / 1;
grid-column: 2 / 2;
  }

  .row--icon {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    color: #e2dfcf;
    display: flex;
    justify-content: center;
    padding-top: 4px;
  } 

  .urgency__grid--disabled {
    height: 48px; 
    width: 100%; 
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 3px 3px;
    padding: 3px;
    background-color: #e2dfcf;
    border-left: 2px solid #004a22;
    border-top: 2px solid #004a22;
    border-radius: 5px 0 0 0;
  }  
  
  .urgency__grid--active {
    height: 24px; 
    width: 35%;
    display: grid; 
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 3px;
    background-color: #e2dfcf;
    border-left: 2px solid #004a22;
    border-top: 2px solid #004a22;
    border-radius: 5px 0 0 0;
  }

  .urgency__input {
    grid-row: auto / span 1;
    grid-column: auto / span 1;
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
    border-radius: 5px;
  }

  .urgency__input:hover {
 opacity: 0.75;
  }

  .quotelist--container {
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
      border-radius: 0px;
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
  }
}

@media (min-width: 2000px) {
  /* Column Styling */
  .objective__col {
    /* min-height: 475px; */
    /* max-height: 65%;  */
    height: auto;
    background-color: rgba(0, 74, 35, 0.1);
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 6px;
    margin: 12px;
    border-radius: 5px;
    overflow: scroll;
  }

  .objective__col.disabled {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px;
    opacity: 0.5;
    overflow: scroll;
  }

  .objective__col:hover {
    opacity: 1;
  }

  /* Objective Name */
  .objective__col--name {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
  }

  .objective__col--name:hover .col__name--icon {
    display: none;
  }

  .col__name--trash {
    display: none;
  }

  .col__name--hand {
    display: none;
  }

  .objective__col--name:hover .col__name--trash,
  .objective__col--name:hover .col__name--hand
   {
    display: block;
    color: #e2dfcf;
    max-height: 24px;
    max-width: 24px;
    margin: 0 12px;
  }

  .objective__col--icon {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.ellipsis {
    padding: 6px 6px 6px 6px;
    color: #004a22;
  }

  .objective__col--icon.ellipsis:hover {
    cursor: pointer;
  }

  .objective__col--icon.trash {
    padding: 6px 6px 6px 12px;
    color: #e2dfcf;
  }

  .objective__col--icon.trash:hover {
    color: #004a22;
    cursor: pointer;
  }

  .col__name--input {
    padding: 12px 6px;
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    box-shadow: none;
    color: #e2dfcf;
    font-weight: bold;
    font-size: 1.1em;
  }

  .col__name--input::placeholder {
    color: #e2dfcf;
    font-weight: bold;
    opacity: 0.7;
  }

  .col__name--icon {
    max-height: 24px;
    max-width: 24px;
    margin-right: 6px;
  }

  .objective__col--wrapper {
    width: 100%;
    display: flex;
    /* z-index: 1000; */
  }

  .reorder {
    display: none;
  }

  .objective__col--wrapper:hover .objective__col--task {
    background-color: #004a22;
  }

  .objective__col--wrapper:hover .task--input,
  .objective__col--wrapper:hover #subTaskInput,
  .objective__col--wrapper:hover .objective__col--icon.ellipsis {
    color: #e2dfcf;
  }

  .objective__col--wrapper:hover .reorder {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .objective__col--reorder {
    padding: 1px 0px 1px 4px;
    margin: 0;
    opacity: 0.125;
  }

  .objective__col--reorder:hover {
    opacity: 1;
    cursor: pointer;
  }

  /* SubTask */
  .objective__col--subTasks {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px;
    margin: 12px 0;
  }

  .objective__col--task {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
    padding: 2px 0;
  }

  .objective__col--task-active {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border: 2px solid #004a22;
    border-radius: 5px;
    margin: 6px 0;
    opacity: 0.5;
  }

  .task__icon--wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task__icon--wrapper div:not(task--radio) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task__radio {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    border: 1px solid #004a22;
    background-color: #e2dfcf;
    margin: 4px 6px;
  }

  .task__radio:hover,
  .task__radio--active {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 50%;
    padding: 1px;
    background-color: #004a22;
    margin: 4px 6px;
    cursor: pointer;
  }

  .task--input {
    width: 100%;
    height: 24px;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    resize: none;
    font-size: 1em;
    padding: 2px;
  }

  .task--input.add {
    padding: 6px 12px;
  }

  .task--input.completed {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: rgba(0, 74, 35, 0.5);
    font-weight: bold;
    font-size: 1em;
    text-decoration: solid line-through #004a22 2px;
  }

  /* Objective Callout  */
  .objective__callout {
    display: none;
  }

  .objective__callout--active {
    min-width: 600px;
    width: auto;
    min-height: 400px;
    height: auto;
    display: grid;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #f5f5f5;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  /* .callout__header {
    grid-column: 1 / 11;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .callout__name {
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 3em;
    padding: 0 12px;
  }

  .callout__name .task__radio {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    margin: 8px 12px;
  }

  .callout--input {
    width: 100%;
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    box-shadow: none;
    color: #004a22;
    font-weight: bold;
    font-size: 1.25em;
    resize: none;
  }

  .callout--icon {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    padding: 12px 12px 12px 24px;
    color: #004a22;
  }

  /* .callout__actions {
    grid-column: 1 / 5;
    grid-row: 3 / 11;
    border-top: 2px solid #004a22;
    border-right: 2px solid #004a22;
    background-color: #e2dfcf;
    padding: 12px;
  } */

  .callout__notes {
    grid-column: 5 / 11;
    grid-row: 3 / 11;
    background-color: #e2dfcf;
    border-top: 2px solid #004a22;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .notes {
    width: 100%;
    height: 80%;
    border: 2px solid #004a22;
    padding: 6px;
    border-radius: 5px;
    resize: none;
  }

  /* Objective Callout  */
  .warning__callout {
    display: none;
  }

  .warning__callout--active {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 25;
    top: 20%;
    left: 25%;
    border-radius: 5px;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }

  .warning__button--row {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .warning__button--row div:hover {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: bold;
    cursor: pointer;
  }

  .warning__button--row div:first-child {
    font-weight: bold;
  }

  .objective__col.inactive {
    opacity: 0.1;
  }

  .quotelist--container {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}



