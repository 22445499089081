@media (max-width: 480px) {
  .yearContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 48px 0;
  }

  #yeartest {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
  }

  .yeartile {
    height: auto;
    width: 95%;
    border-radius: 8px;
    margin: 12px 0;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }

  .tutorial.access {
    width: 100%;
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px 5px 0 0;
  }

  .tutorial.access--tutorial {
    width: 100%;
    background-color: rgba(103, 36, 131, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px 5px 0 0;
  }

  .iconDiv {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .tutorial.access label {
    color: #e2dfcf;
    margin-left: 6px;
  }

  .tutorial.access .icon {
    color: #e2dfcf;
  }

  /* .birthday {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  } */

  .birthdayInput {
    height: 100%;
    min-width: 100%;
    background-color: #004a22;
    color: #e2dfcf;
    outline: none;
    border: none;
    font-family: monospace;
    padding: 12px 8px;
    border-radius: 0 0 5px 5px;
    appearance: none; /* Removes default styling */
    -webkit-appearance: none; /* Removes default styling on WebKit browsers */
    -moz-appearance: none; /* Removes default styling on Firefox */
    text-align: start;
  }

  .birthdayInput:focus,
  .birthdayInput:active
  {
    outline: none;
    min-width: 100%;
    appearance: none; /* Removes default styling */
    -webkit-appearance: none; /* Removes default styling on WebKit browsers */
    -moz-appearance: none; /* Removes default styling on Firefox */
  }

  .birthdayInput::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }

  .valuesDiv textarea,
  .valuesDiv input,
  .yn textarea,
  .ikigai textarea,
  .quote textarea,
  .memory textarea,
  .continuation textarea,
  .memory span {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .mobileHeader {
    background-color: #004a22;
    padding: 12px 12px 0 24px;
    width: 100%;
  }

  .formInput.year {
    overflow: hidden;
    width: 100%;
    padding: 4px;
  }

  .formInput.year::placeholder {
    font-weight: 100;
    color: #004a22;
    opacity: 1;
  }

  #yeartilevalues,
  #yeartilevision {
    height: 100%;
    width: 95%;
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .valuesRow {
    width: 100%;
  }

  .valuesDiv input {
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
    font-weight: bold;
  }

  .valuesDiv {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px dashed rgba(125, 125, 125, 0.2);
  }

  .valuesDiv:first-child {
    padding-top: 3px;
  }

  .valuesDiv:last-child {
    border-bottom: none;
  }

  .helpText a {
    color: #e2dfcf;
  }

  #yourName {
    width: 100%;
  }

  .statement.starter {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 0.8em;
    font-weight: 200;
    color: #004a22;
  }

  .icon.birthday {
    padding: 4px;
    color: #004a22;
    opacity: 0.5;
  }

  .newIcon {
    height: 18px;
    width: 18px;
    margin: 0 0 3px 0;
  }

  .newIcon.loading {
    height: 32px;
    width: 32px;
    position: relative;
    top: 24px;
  }

  .birthRow {
    width: 100%;
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #valuesOne,
  #valuesTwo,
  #valuesThree,
  #valuesFour,
  #valuesFive {
    width: 100%;
  }

  .yeartile.quote {
    width: 95%;
  }

  .helpText {
    background-color: #004a22;
    color: #f5f5f5;
  }

  .dock.node.tutorial {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .yearContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 48px 0;
  }

  #yeartest {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
  }

  .yeartile {
    height: auto;
    width: 95%;
    border-radius: 8px; /* Adjusted to match the first query */
    margin: 12px 0;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Consistent shadow */
    border: 1px solid #ddd; /* Added for consistency */
  }

  .tutorial.access {
    width: 100%;
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px 5px 0 0;
  }

  .tutorial.access--tutorial {
    width: 100%;
    background-color: rgba(103, 36, 131, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px 5px 0 0;
  }

  .iconDiv {
    width: 20%; /* Aligned with the first query */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .icon_div .icon:last-child {
    color: #e2dfcf;
  }

  .tutorial.access label {
    color: #e2dfcf;
  }

  .tutorial.access .icon {
    color: #e2dfcf;
    transform: scale(1); /* Adjusted for consistency */
  }

  .birthday {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .birthdayInput {
    height: 100%;
    width: 100%;
    background-color: #004a22;
    color: #e2dfcf;
    outline: none;
    border: none;
    font-family: monospace;
    padding: 12px 8px;
    border-radius: 0 0 5px 5px;
  }

  .birthdayInput:focus {
    outline: none;
  }

  .birthdayInput::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }

  .valuesDiv input,
  .valuesDiv textarea,
  .yn textarea,
  .ikigai textarea,
  .quote textarea,
  .memory textarea,
  .continuation textarea,
  .memory span {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .valuesDiv input {
    font-size: 1.1em; /* Consistent font size */
    font-weight: bold; /* Aligned with the first query */
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .valuesDiv {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px dashed rgba(125, 125, 125, 0.2);
  }

  .valuesDiv:first-child {
    padding-top: 3px;
  }

  .valuesDiv:last-child {
    border-bottom: none;
  }

  .mobileHeader {
    background-color: #004a22;
    padding: 12px 12px 0 24px;
    width: 100%;
  }

  .formInput.year {
    overflow: hidden;
    width: 100%;
    padding: 4px;
  }

  .formInput.year::placeholder {
    font-weight: 100;
    color: #004a22;
    opacity: 1;
  }

  #yeartilevalues,
  #yeartilevision {
    height: 100%;
    width: 95%;
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .valuesRow {
    width: 100%;
  }

  .helpText a {
    color: #e2dfcf;
  }

  #yourName {
    width: 100%;
  }

  .statement.starter {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 0.8em;
    font-weight: 200;
    color: #004a22;
  }

  .icon.birthday {
    padding: 4px;
    color: #004a22;
    opacity: 0.5;
  }

  .newIcon {
    height: 18px; /* Consistent size */
    width: 18px;
    margin: 0 0 3px 0;
  }

  .newIcon.loading {
    height: 32px; /* Adjusted to match scaling logic */
    width: 32px;
    position: relative;
    top: 24px;
  }

  .birthRow {
    width: 100%;
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #valuesOne,
  #valuesTwo,
  #valuesThree,
  #valuesFour,
  #valuesFive {
    width: 100%;
  }

  .yeartile.quote {
    width: 95%;
  }

  .helpText {
    background-color: #004a22;
    color: #f5f5f5;
  }

  .dock.node.tutorial {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .yearContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
  }

  #yeartest {
    height: auto;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    row-gap: 24px;
    grid-template-rows: repeat(auto, 75px);
    justify-items: center;
  }

  .yeartile {
    height: min-content;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
  }

  .name {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }


  .birthday {
    grid-column: 1 / 1;
    grid-row: auto / span 1;
    height: min-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.05);
  }

  .birthdayInput {
    width: 100%;
    background-color: transparent;
    color: #004a22;
    border: 1px solid rgba(125, 125, 125, 0.5);
    padding: 8px;
    border-radius: 5px;
  }

  .birthdayInput:focus {
    border-color: #004a22;
    outline: none;
  }

  .continuation {
    grid-column: 1 / 1;
    grid-row: 3 / 3;
  }

  .memory {
    grid-column: 1 / 1;
    grid-row: auto / span 1;
  }

  .quote {
    grid-column: 1 / 1;
    grid-row: auto / span 2;
  }

  .ikigai {
    grid-column: 1 / 1;
    grid-row: auto / span 2;
  }

  .values {
    grid-column: 2 / 4;
    grid-row: 1 / 4;
  }

  .vision {
    grid-column: 2 / 4;
    grid-row: 4 / span 5;
  }

  .tutorial.access {
    width: 100%;
    background-color: #004a22;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-radius: 5px 5px 0 0;
    color: #e2dfcf;
  }

  .valuesRow {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .iconDiv {
    width: 20%; /* Aligned with the first query */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .valuesDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.3);
  }

  .valuesDiv input {
    width: 100%;
    font-weight: 600;
    border: none;
    padding: 4px 8px;
    color: #004a22;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.3);
  }

  .valuesDiv textarea {
    width: 100%;
    border: none;
    padding: 4px 8px;
    resize: none;
    outline: none;
    margin-bottom: 4px;
  }



  .helpText {
    background-color: #004a22;
    color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 5px;
  }

  .newIcon {
    height: 24px;
    width: 24px;
  }

  .newIcon.loading {
    height: 48px;
    width: 48px;
    margin-right: 8px;
  }

  .mobileHeader {
    background-color: #004a22;
    padding: 12px 16px;
    width: 100%;
    color: #e2dfcf;
  }

  #yourName {
    font-size: 1.1em;
    font-weight: 600;
    width: 100%;
  }

  .icon.birthday {
    padding: 4px;
    color: #004a22;
    transform: scale(0.75);
    opacity: 0.7;
  }

  .dock.node.tutorial {
    min-height: 48px;
    min-width: 48px;
    border-radius: 50%;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
  }

  .dock.node.tutorial:hover {
    transform: scale(1.1);
  }

  .formInput.year {
    overflow: hidden;
    width: 100%;
    padding: 4px;
  }

  .formInput.year::placeholder {
    font-weight: 100;
    color: #004a22;
    opacity: 1;
  }
}


@media (min-width: 1025px) and (max-width: 1200px) {
  .yearContainer {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 18px;
  }

  #yeartest {
    height: 75vh;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 12px;
    grid-template-rows: repeat(4, 1fr);
    column-gap: 12px;
  }

  .iconDiv {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  #yeartesttutorial {
    height: 75vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 8px;
    grid-template-rows: repeat(4, 1fr);
    column-gap: 8px;
    margin: 24px 6px 0 6px;
  }

  .yeartile {
    background-color: whitesmoke;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
  }

  .statement.starter {
    display: none;
  }

  .formInput.year::placeholder {
    font-weight: 200;
    color: #004a22;
    opacity: 0.5;
  }

  .yn.active {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    overflow: hidden;
  }

  .yn {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  #yourName {
    width: 100%;
    height: 100%;
  }

  .birthday {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .birthday.active {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .birthday span {
    padding: 4px 8px 4px 6px;
    color: #004a22;
  }

  .birthRow {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #f5f5f5;
  }

  .birthRow div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .icon.birthday {
    padding: 4px;
    color: #004a22;
    transform: scale(0.75);
    opacity: 0.5;
  }

  .newIcon {
    height: 20px;
    width: 20px;
  }

  .newIcon.loading {
    height: 46px;
    width: 46px;
    margin-right: 6px;
  }

  .birthdayInput {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 6px;
    border-radius: 0 0 5px 5px;
  }

  .continuation {
    grid-row: 2 / 2;
    grid-column: 7 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .continuation.active {
    grid-row: 2 / 2;
    grid-column: 7 / span 2;
    background-color: #004a22;
    color: #f5f5f5;
  }

  .values {
    grid-row: 3 / span 2;
    grid-column: 1 / span 4;
    overflow-y: hidden;
  }

  .values.active {
    grid-row: 3 / span 2;
    grid-column: 1 / span 4;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .yeartile h1,
  .yeartile h2 {
    padding: 12px;
    color: #e2dfcf;
  }

  .tutorial.access {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
    padding: 4px 8px;
    background-color: #004a22;
    border-radius: 5px 5px 0 0;
  }

  .tutorial.access label {
    color: #e2dfcf;
  }

  .tutorial.access .icon {
    color: #e2dfcf;
    transform: scale(0.8);
  }

  .valuesRow {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .valuesRow div:last-child {
    border-right: none;
  }

  .valuesDiv {
    height: 110%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding-top: 4px;
    border-right: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .valuesDiv label {
    padding: 4px;
  }

  .valuesDiv textarea,
  .name textarea,
  .ikigai textarea,
  .quote textarea,
  .memory textarea,
  .continuation textarea {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .valuesDiv textarea::placeholder,
  .quote textarea::placeholder {
    font-weight: 200;
    color: #004a22;
    opacity: 0.5;
  }

  .valuesDiv input {
    width: 100%;
    font-size: 1.1em;
    font-weight: 600;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
    padding: 4px 6px;
    color: #004a22;
  }

  #yourName {
    font-size: 1.3em;
    font-weight: 600;
  }

  .vision {
    grid-row: 1 / span 2;
    grid-column: 2 / span 5;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    overflow-y: hidden;
  }

  .vision.active {
    grid-row: 1 / span 2;
    grid-column: 2 / span 5;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .quote {
    grid-row: 3 / span 2;
    grid-column: 5 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .quote.active {
    grid-row: 3 / span 2;
    grid-column: 5 / span 2;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  #iki,
  #cont,
  #navQuote,
  #memory {
    width: 100%;
    height: 100%;
  }

  .ikigai {
    grid-row: 1 / 1;
    grid-column: 7 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .ikigai.active {
    grid-row: 1 / 1;
    grid-column: 7 / span 2;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .memory.active {
    grid-row: 3 / span 2;
    grid-column: 7 / span 2;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .memory {
    grid-row: 3 / span 2;
    grid-column: 7 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .helpText {
    display: none;
  }

  .question_mark {
    display: none;
  }

  .score_label {
    opacity: 1;
  }
}

@media (min-width: 1201px) {
  .yearContainer {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  #yeartest {
    height: 75vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 10px;
    grid-template-rows: repeat(4, 1fr);
    column-gap: 10px;
    padding: 0 36px;
  }

  #yeartesttutorial {
    height: 75vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 8px;
    grid-template-rows: repeat(4, 1fr);
    column-gap: 8px;
    margin: 24px 6px 0 6px;
  }

  .yeartile {
    background-color: whitesmoke;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
  }

  .formInput.year {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 6px;
  }

  .statement.starter {
    display: none;
  }

  .formInput.year::placeholder {
    font-weight: 200;
    color: #004a22;
    opacity: 0.5;
  }

  .name.active {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    overflow: hidden;
  }

  .name {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  #yourName {
    width: 100%;
    height: 100%;
  }

  .birthday {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .birthday.active {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .birthday span {
    padding: 4px 8px 4px 6px;
    color: #004a22;
  }

  .birthRow {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #f5f5f5;
  }

  .birthRow div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .icon.birthday {
    padding: 4px;
    color: #004a22;
    transform: scale(0.75);
    opacity: 0.5;
  }

  .newIcon {
    height: 20px;
    width: 20px;
  }

  .newIcon.loading {
    height: 46px;
    width: 46px;
    margin-right: 6px;
  }

  .birthdayInput {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 6px;
    border-radius: 0 0 5px 5px;
  }

  .continuation {
    grid-row: 2 / 2;
    grid-column: 7 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .continuation.active {
    grid-row: 2 / 2;
    grid-column: 7 / span 2;
    background-color: #004a22;
    color: #f5f5f5;
  }

  .values {
    grid-row: 3 / span 2;
    grid-column: 1 / span 4;
    overflow-y: hidden;
  }

  .values.active {
    grid-row: 3 / span 2;
    grid-column: 1 / span 4;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .yeartile h1,
  .yeartile h2 {
    padding: 12px;
    color: #e2dfcf;
  }

  .tutorial.access {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
    padding: 4px 8px;
    background-color: #004a22;
    border-radius: 5px 5px 0 0;
  }

  .tutorial.access label {
    color: #e2dfcf;
  }

  .tutorial.access .icon {
    color: #e2dfcf;
    transform: scale(0.8);
  }

  .valuesRow {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .valuesRow div:last-child {
    border-right: none;
  }

  .valuesDiv {
    height: 110%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding-top: 4px;
    border-right: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .valuesDiv label {
    padding: 4px;
  }

  .valuesDiv textarea,
  .name textarea,
  .ikigai textarea,
  .quote textarea,
  .memory textarea,
  .continuation textarea {
    height: 100%;
    width: 100%;
    font-size: 1.1em; 
    font-weight: 600;
    color: #004a22;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
    resize: none;
  }

  .valuesDiv textarea::placeholder,
  .quote textarea::placeholder {
    font-weight: 200;
    color: #004a22;
    opacity: 0.5;
  }

  .valuesDiv input {
    width: 100%;
    font-size: 1.1em;
    font-weight: 600;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
    padding: 4px 6px;
    color: #004a22;
  }

  #yourName {
    font-size: 1.3em;
    font-weight: 600;
  }

  .vision {
    grid-row: 1 / span 2;
    grid-column: 2 / span 5;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    overflow-y: hidden;
  }

  .vision.active {
    grid-row: 1 / span 2;
    grid-column: 2 / span 5;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .quote {
    grid-row: 3 / span 2;
    grid-column: 5 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .quote.active {
    grid-row: 3 / span 2;
    grid-column: 5 / span 2;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  #iki,
  #cont,
  #navQuote,
  #memory {
    width: 100%;
    height: 100%;
  }

  .ikigai {
    grid-row: 1 / 1;
    grid-column: 7 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .ikigai.active {
    grid-row: 1 / 1;
    grid-column: 7 / span 2;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .memory.active {
    grid-row: 3 / span 2;
    grid-column: 7 / span 2;
    white-space: normal;
    z-index: 10;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .memory {
    grid-row: 3 / span 2;
    grid-column: 7 / span 2;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    height: 100%;
    width: 100%;
  }

  .helpText {
    display: none;
  }

  .question_mark {
    display: none;
  }

  .score_label {
    opacity: 1;
  }

  .icon_div {
    display: none;
  }

  .iconDiv {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
