@import url("https://use.typekit.net/quw2cgr.css");

/* General Accessibility */
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 12px;
}

body {
  height: 100%;
  background-color: #e2dfcf;
  padding: 0;
  margin: 0;
}

/* Text Styles */
h1,
h2,
h3,
h4,
h5,
h6,
nav,
nav a,
#nava,
.smallSpan,
.rect span,
.tileLoading p,
.aboutHeader,
.aboutPageHeader,
strong,
button,
div::before,
div:hover::before,
#openDock,
.tileLoading strong,
.storySection a,
.landingPage.mobile a,
.question,
.debriefRow_title label,
.navElement span,
.searchInput,
.searchInput:focus,
.searchInput:active,
.searchInput::placeholder,
.statSpan,
.custom--radio,
.scoring__row span,
.moment__submission span,
.blog__toolbar a,
.blog__toolbar label,
.links-section h2,
.links-section ul li a,
.option-style a,
.blog__category--list a,
.review__name p:first-child,
.callout__header--input, 
.journal--log--table--row td, 
.birthdayInput,
.about-us-header, 
.full-width p,
.faq-question span {
  font-family: "basic-sans", Tahoma, Verdana, sans-serif;
}

span,
label,
ul,
li,
p,
i,
input,
input::placeholder,
textarea,
textarea::placeholder,
option,
select,
#dp,
#loggedDate,
#action,
.textarea-tooltip,
.objective__col--name,
.moment__callout--active,
.callout__button,
.tier--features {
  font-family: work-sans, sans-serif;
}

a {
  color: #004a22;
}

/* Scrollbars */
.tile::-webkit-scrollbar,
.objective__col::-webkit-scrollbar,
.weekTile textarea::-webkit-scrollbar,
.obj__card::-webkit-scrollbar,
.insighttile::-webkit-scrollbar,
.faq__tile--answer::-webkit-scrollbar,
.faq__tile--question::-webkit-scrollbar,
.story__frame.story__frame_live::-webkit-scrollbar,
.leftCol::-webkit-scrollbar,
.leftCol.silvs::-webkit-scrollbar,
.board--scale::-webkit-scrollbar {
  width: 0px;
  background-color: rgba(0, 74, 35, 0.05);
  border-radius: 5px;
}

.tile::-webkit-scrollbar-thumb,
.objective__col::-webkit-scrollbar-thumb,
.weekTile textarea::-webkit-scrollbar-thumb,
.obj__card::-webkit-scrollbar-thumb,
.insighttile::-webkit-scrollbar-thumb,
.faq__tile--answer::-webkit-scrollbar-thumb,
.faq__tile--question::-webkit-scrollbar-thumb,
.story__frame.story__frame_live::-webkit-scrollbar-thumb
  .leftCol::-webkit-scrollbar-thumb,
.leftCol.silvs::-webkit-scrollbar-thumb,
.board--scale::-webkit-scrollbar-thumb {
  background-color: rgba(0, 74, 35, 0.3);
  width: 7px;
  height: 7px;
  border-radius: 5px;
}

.nav-tabs::-webkit-scrollbar,
.tabs.nav.nav-tabs::-webkit-scrollbar,
nav.nav-tabs::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.nav-tabs::-webkit-scrollbar-thumb,
.tabs.nav.nav-tabs::-webkit-scrollbar-thumb,
nav.nav-tabs::-webkit-scrollbar-thumb {
  height: 0px;
  background-color: none;
  border-radius: 0px;
}

.nav-tabs::-webkit-scrollbar-track,
.tabs.nav.nav-tabs::-webkit-scrollbar-track,
nav.nav-tabs::-webkit-scrollbar-track {
  height: 0px;
  width: 0px;
}

@media (max-width: 480px) {
  /* Font Size */
  textarea,
  #loggedDate,
  label,
  li,
  span,
  input, 
  strong  {
    font-size: medium;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  textarea,
  #loggedDate,
  label,
  li,
  span,
  input {
    font-size: 1.25em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  textarea,
  #loggedDate,
  label,
  li,
  span,
  input {
    font-size: 1.25em;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {

}

@media (min-width: 1201px) {
  .main__container {
    height: 100vh;
    width: 100%;
  }
}